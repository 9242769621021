# Made in Blockbench 4.9.4
mtllib materials.mtl

o body
v 0.09375 0.1875 0.03125
v 0.09375 0.1875 -0.15625
v 0.09375 0.0625 0.03125
v 0.09375 0.0625 -0.15625
v -0.09375 0.1875 -0.15625
v -0.09375 0.1875 0.03125
v -0.09375 0.0625 -0.15625
v -0.09375 0.0625 0.03125
vt 0.1875 0.8125
vt 0.375 0.8125
vt 0.375 0.6875
vt 0.1875 0.6875
vt 0 0.8125
vt 0.1875 0.8125
vt 0.1875 0.6875
vt 0 0.6875
vt 0.5625 0.8125
vt 0.75 0.8125
vt 0.75 0.6875
vt 0.5625 0.6875
vt 0.375 0.8125
vt 0.5625 0.8125
vt 0.5625 0.6875
vt 0.375 0.6875
vt 0.375 0.8125
vt 0.1875 0.8125
vt 0.1875 1
vt 0.375 1
vt 0.5625 1
vt 0.375 1
vt 0.375 0.8125
vt 0.5625 0.8125
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_71a3df64-a731-05a3-e848-ff24e21209ec
f 4/4/1 7/3/1 5/2/1 2/1/1
f 3/8/2 4/7/2 2/6/2 1/5/2
f 8/12/3 3/11/3 1/10/3 6/9/3
f 7/16/4 8/15/4 6/14/4 5/13/4
f 6/20/5 1/19/5 2/18/5 5/17/5
f 7/24/6 4/23/6 3/22/6 8/21/6
o tail
v 0 0.1875 0.40625
v 0 0.1875 -0.03125
v 0 0.0625 0.40625
v 0 0.0625 -0.03125
v 0 0.1875 -0.03125
v 0 0.1875 0.40625
v 0 0.0625 -0.03125
v 0 0.0625 0.40625
vt 0.4375 0.5625
vt 0.4375 0.5625
vt 0.4375 0.4375
vt 0.4375 0.4375
vt 0 0.5625
vt 0.4375 0.5625
vt 0.4375 0.4375
vt 0 0.4375
vt 0.875 0.5625
vt 0.875 0.5625
vt 0.875 0.4375
vt 0.875 0.4375
vt 0.4375 0.5625
vt 0.875 0.5625
vt 0.875 0.4375
vt 0.4375 0.4375
vt 0.4375 0.5625
vt 0.4375 0.5625
vt 0.4375 1
vt 0.4375 1
vt 0.4375 1
vt 0.4375 1
vt 0.4375 0.5625
vt 0.4375 0.5625
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_71a3df64-a731-05a3-e848-ff24e21209ec
f 12/28/7 15/27/7 13/26/7 10/25/7
f 11/32/8 12/31/8 10/30/8 9/29/8
f 16/36/9 11/35/9 9/34/9 14/33/9
f 15/40/10 16/39/10 14/38/10 13/37/10
f 14/44/11 9/43/11 10/42/11 13/41/11
f 15/48/12 12/47/12 11/46/12 16/45/12