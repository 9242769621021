# Made in Blockbench 4.9.4
mtllib materials.mtl

o neck
v 0.3125 0.7488108431536706 -0.47276383039135683
v 0.3125 0.803283182370957 -1.0953855166986979
v 0.3125 0.1261891568463296 -0.5272361696086432
v 0.3125 0.18066149606361592 -1.1498578559159842
v -0.3125 0.803283182370957 -1.0953855166986979
v -0.3125 0.7488108431536706 -0.47276383039135683
v -0.3125 0.18066149606361592 -1.1498578559159842
v -0.3125 0.1261891568463296 -0.5272361696086432
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 0.08715574274765818 -0.9961946980917455
vn 1 0 0
vn 0 -0.08715574274765818 0.9961946980917455
vn -1 0 0
vn 0 0.9961946980917455 0.08715574274765818
vn 0 -0.9961946980917455 -0.08715574274765818
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 4/4/1 7/3/1 5/2/1 2/1/1
f 3/8/2 4/7/2 2/6/2 1/5/2
f 8/12/3 3/11/3 1/10/3 6/9/3
f 7/16/4 8/15/4 6/14/4 5/13/4
f 6/20/5 1/19/5 2/18/5 5/17/5
f 7/24/6 4/23/6 3/22/6 8/21/6
o neck
v 0.0625 1.0087539855200642 -0.5754992319659105
v 0.0625 1.041437389050436 -0.9490722437503151
v 0.0625 0.7597053109971279 -0.597288167652825
v 0.0625 0.7923887145274997 -0.9708611794372296
v -0.0625 1.041437389050436 -0.9490722437503151
v -0.0625 1.0087539855200642 -0.5754992319659105
v -0.0625 0.7923887145274997 -0.9708611794372296
v -0.0625 0.7597053109971279 -0.597288167652825
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 0.08715574274765818 -0.9961946980917455
vn 1 0 0
vn 0 -0.08715574274765818 0.9961946980917455
vn -1 0 0
vn 0 0.9961946980917455 0.08715574274765818
vn 0 -0.9961946980917455 -0.08715574274765818
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 12/28/7 15/27/7 13/26/7 10/25/7
f 11/32/8 12/31/8 10/30/8 9/29/8
f 16/36/9 11/35/9 9/34/9 14/33/9
f 15/40/10 16/39/10 14/38/10 13/37/10
f 14/44/11 9/43/11 10/42/11 13/41/11
f 15/48/12 12/47/12 11/46/12 16/45/12
o neck
v 0.3125 0.8044723392172863 -1.1226216863073408
v 0.3125 0.8044723392172863 -1.7476216863073408
v 0.3125 0.17947233921728634 -1.1226216863073408
v 0.3125 0.17947233921728634 -1.7476216863073408
v -0.3125 0.8044723392172863 -1.7476216863073408
v -0.3125 0.8044723392172863 -1.1226216863073408
v -0.3125 0.17947233921728634 -1.7476216863073408
v -0.3125 0.17947233921728634 -1.1226216863073408
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 20/52/13 23/51/13 21/50/13 18/49/13
f 19/56/14 20/55/14 18/54/14 17/53/14
f 24/60/15 19/59/15 17/58/15 22/57/15
f 23/64/16 24/63/16 22/62/16 21/61/16
f 22/68/17 17/67/17 18/66/17 21/65/17
f 23/72/18 20/71/18 19/70/18 24/69/18
o neck
v 0.0625 1.0544723392172863 -1.2476216863073408
v 0.0625 1.0544723392172863 -1.6226216863073408
v 0.0625 0.8044723392172863 -1.2476216863073408
v 0.0625 0.8044723392172863 -1.6226216863073408
v -0.0625 1.0544723392172863 -1.6226216863073408
v -0.0625 1.0544723392172863 -1.2476216863073408
v -0.0625 0.8044723392172863 -1.6226216863073408
v -0.0625 0.8044723392172863 -1.2476216863073408
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 28/76/19 31/75/19 29/74/19 26/73/19
f 27/80/20 28/79/20 26/78/20 25/77/20
f 32/84/21 27/83/21 25/82/21 30/81/21
f 31/88/22 32/87/22 30/86/22 29/85/22
f 30/92/23 25/91/23 26/90/23 29/89/23
f 31/96/24 28/95/24 27/94/24 32/93/24
o neck
v 0.3125 0.803283182370957 -1.774857855915984
v 0.3125 0.7488108431536706 -2.3974795422233246
v 0.3125 0.18066149606361592 -1.7203855166986977
v 0.3125 0.12618915684632948 -2.3430072030060387
v -0.3125 0.7488108431536706 -2.3974795422233246
v -0.3125 0.803283182370957 -1.774857855915984
v -0.3125 0.12618915684632948 -2.3430072030060387
v -0.3125 0.18066149606361592 -1.7203855166986977
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.08715574274765818 -0.9961946980917455
vn 1 0 0
vn 0 0.08715574274765818 0.9961946980917455
vn -1 0 0
vn 0 0.9961946980917455 -0.08715574274765818
vn 0 -0.9961946980917455 0.08715574274765818
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 36/100/25 39/99/25 37/98/25 34/97/25
f 35/104/26 36/103/26 34/102/26 33/101/26
f 40/108/27 35/107/27 33/106/27 38/105/27
f 39/112/28 40/111/28 38/110/28 37/109/28
f 38/116/29 33/115/29 34/114/29 37/113/29
f 39/120/30 36/119/30 35/118/30 40/117/30
o neck
v 0.0625 1.041437389050436 -1.9211711288643667
v 0.0625 1.0087539855200642 -2.2947441406487714
v 0.0625 0.7923887145274997 -1.8993821931774522
v 0.0625 0.7597053109971279 -2.272955204961857
v -0.0625 1.0087539855200642 -2.2947441406487714
v -0.0625 1.041437389050436 -1.9211711288643667
v -0.0625 0.7597053109971279 -2.272955204961857
v -0.0625 0.7923887145274997 -1.8993821931774522
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.08715574274765818 -0.9961946980917455
vn 1 0 0
vn 0 0.08715574274765818 0.9961946980917455
vn -1 0 0
vn 0 0.9961946980917455 -0.08715574274765818
vn 0 -0.9961946980917455 0.08715574274765818
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 44/124/31 47/123/31 45/122/31 42/121/31
f 43/128/32 44/127/32 42/126/32 41/125/32
f 48/132/33 43/131/33 41/130/33 46/129/33
f 47/136/34 48/135/34 46/134/34 45/133/34
f 46/140/35 41/139/35 42/138/35 45/137/35
f 47/144/36 44/143/36 43/142/36 48/141/36
o neck
v 0.3125 0.7452524228163151 -2.424508428135597
v 0.3125 0.6367223117744836 -3.0400132737682273
v 0.3125 0.1297475771836849 -2.315978317093766
v 0.3125 0.02121746614185338 -2.931483162726396
v -0.3125 0.6367223117744836 -3.0400132737682273
v -0.3125 0.7452524228163151 -2.424508428135597
v -0.3125 0.02121746614185338 -2.931483162726396
v -0.3125 0.1297475771836849 -2.315978317093766
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.17364817766693036 -0.9848077530122081
vn 1 0 0
vn 0 0.17364817766693036 0.9848077530122081
vn -1 0 0
vn 0 0.9848077530122081 -0.17364817766693036
vn 0 -0.9848077530122081 0.17364817766693036
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 52/148/37 55/147/37 53/146/37 50/145/37
f 51/152/38 52/151/38 50/150/38 49/149/38
f 56/156/39 51/155/39 49/154/39 54/153/39
f 55/160/40 56/159/40 54/158/40 53/157/40
f 54/164/41 49/163/41 50/162/41 53/161/41
f 55/168/42 52/167/42 51/166/42 56/165/42
o neck
v 0.0625 0.9697483388610009 -2.591021441678856
v 0.0625 0.9046302722359019 -2.960324349058434
v 0.0625 0.7235464006079488 -2.5476093972621237
v 0.0625 0.6584283339828498 -2.9169123046417016
v -0.0625 0.9046302722359019 -2.960324349058434
v -0.0625 0.9697483388610009 -2.591021441678856
v -0.0625 0.6584283339828498 -2.9169123046417016
v -0.0625 0.7235464006079488 -2.5476093972621237
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.17364817766693036 -0.9848077530122081
vn 1 0 0
vn 0 0.17364817766693036 0.9848077530122081
vn -1 0 0
vn 0 0.9848077530122081 -0.17364817766693036
vn 0 -0.9848077530122081 0.17364817766693036
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 60/172/43 63/171/43 61/170/43 58/169/43
f 59/176/44 60/175/44 58/174/44 57/173/44
f 64/180/45 59/179/45 57/178/45 62/177/45
f 63/184/46 64/183/46 62/182/46 61/181/46
f 62/188/47 57/187/47 58/186/47 61/185/47
f 63/192/48 60/191/48 59/190/48 64/189/48
o neck
v 0.3125 0.6308217096735025 -3.0666291698418497
v 0.3125 0.469059806484427 -3.670332811272517
v 0.3125 0.027118068242834803 -2.904867266652774
v 0.3125 -0.13464383494624066 -3.5085709080834415
v -0.3125 0.469059806484427 -3.670332811272517
v -0.3125 0.6308217096735025 -3.0666291698418497
v -0.3125 -0.13464383494624066 -3.5085709080834415
v -0.3125 0.027118068242834803 -2.904867266652774
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.25881904510252074 -0.9659258262890683
vn 1 0 0
vn 0 0.25881904510252074 0.9659258262890683
vn -1 0 0
vn 0 0.9659258262890683 -0.25881904510252074
vn 0 -0.9659258262890683 0.25881904510252074
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 68/196/49 71/195/49 69/194/49 66/193/49
f 67/200/50 68/199/50 66/198/50 65/197/50
f 72/204/51 67/203/51 65/202/51 70/201/51
f 71/208/52 72/207/52 70/206/52 69/205/52
f 70/212/53 65/211/53 66/210/53 69/209/53
f 71/216/54 68/215/54 67/214/54 72/213/54
o neck
v 0.0625 0.8399507856079544 -3.252074659403613
v 0.0625 0.7428936436945093 -3.6142968442620136
v 0.0625 0.5984693290356873 -3.187369898127983
v 0.0625 0.5014121871222421 -3.5495920829863836
v -0.0625 0.7428936436945093 -3.6142968442620136
v -0.0625 0.8399507856079544 -3.252074659403613
v -0.0625 0.5014121871222421 -3.5495920829863836
v -0.0625 0.5984693290356873 -3.187369898127983
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.25881904510252074 -0.9659258262890683
vn 1 0 0
vn 0 0.25881904510252074 0.9659258262890683
vn -1 0 0
vn 0 0.9659258262890683 -0.25881904510252074
vn 0 -0.9659258262890683 0.25881904510252074
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 76/220/55 79/219/55 77/218/55 74/217/55
f 75/224/56 76/223/56 74/222/56 73/221/56
f 80/228/57 75/227/57 73/226/57 78/225/57
f 79/232/58 80/231/58 78/230/58 77/229/58
f 78/236/59 73/235/59 74/234/59 77/233/59
f 79/240/60 76/239/60 75/238/60 80/237/60
o head
v 0.375 -0.07332885084174778 -4.433059161823504
v 0.375 -0.41534899416741644 -5.372751782609413
v 0.375 -0.3669827948373441 -4.326177867034232
v 0.375 -0.7090029381630127 -5.265870487820141
v -0.375 -0.41534899416741644 -5.372751782609413
v -0.375 -0.07332885084174778 -4.433059161823504
v -0.375 -0.7090029381630127 -5.265870487820141
v -0.375 -0.3669827948373441 -4.326177867034232
vt 0.75 0.765625
vt 0.796875 0.765625
vt 0.796875 0.74609375
vt 0.75 0.74609375
vt 0.6875 0.765625
vt 0.75 0.765625
vt 0.75 0.74609375
vt 0.6875 0.74609375
vt 0.859375 0.765625
vt 0.90625 0.765625
vt 0.90625 0.74609375
vt 0.859375 0.74609375
vt 0.796875 0.765625
vt 0.859375 0.765625
vt 0.859375 0.74609375
vt 0.796875 0.74609375
vt 0.796875 0.765625
vt 0.75 0.765625
vt 0.75 0.828125
vt 0.796875 0.828125
vt 0.84375 0.828125
vt 0.796875 0.828125
vt 0.796875 0.765625
vt 0.84375 0.765625
vn 0 -0.34202014332566877 -0.9396926207859084
vn 1 0 0
vn 0 0.34202014332566877 0.9396926207859084
vn -1 0 0
vn 0 0.9396926207859084 -0.34202014332566877
vn 0 -0.9396926207859084 0.34202014332566877
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 84/244/61 87/243/61 85/242/61 82/241/61
f 83/248/62 84/247/62 82/246/62 81/245/62
f 88/252/63 83/251/63 81/250/63 86/249/63
f 87/256/64 88/255/64 86/254/64 85/253/64
f 86/260/65 81/259/65 82/258/65 85/257/65
f 87/264/66 84/263/66 83/262/66 88/261/66
o head
v 0.5 0.6370542961620473 -3.760461931340814
v 0.5 0.2950341528363787 -4.7001545521267225
v 0.5 -0.3026383246238611 -3.418441788015145
v 0.5 -0.6446584679495297 -4.358134408801053
v -0.5 0.2950341528363787 -4.7001545521267225
v -0.5 0.6370542961620473 -3.760461931340814
v -0.5 -0.6446584679495297 -4.358134408801053
v -0.5 -0.3026383246238611 -3.418441788015145
vt 0.5 0.8203125
vt 0.5625 0.8203125
vt 0.5625 0.7578125
vt 0.5 0.7578125
vt 0.4375 0.8203125
vt 0.5 0.8203125
vt 0.5 0.7578125
vt 0.4375 0.7578125
vt 0.625 0.8203125
vt 0.6875 0.8203125
vt 0.6875 0.7578125
vt 0.625 0.7578125
vt 0.5625 0.8203125
vt 0.625 0.8203125
vt 0.625 0.7578125
vt 0.5625 0.7578125
vt 0.5625 0.8203125
vt 0.5 0.8203125
vt 0.5 0.8828125
vt 0.5625 0.8828125
vt 0.625 0.8828125
vt 0.5625 0.8828125
vt 0.5625 0.8203125
vt 0.625 0.8203125
vn 0 -0.34202014332566877 -0.9396926207859084
vn 1 0 0
vn 0 0.34202014332566877 0.9396926207859084
vn -1 0 0
vn 0 0.9396926207859084 -0.34202014332566877
vn 0 -0.9396926207859084 0.34202014332566877
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 92/268/67 95/267/67 93/266/67 90/265/67
f 91/272/68 92/271/68 90/270/68 89/269/68
f 96/276/69 91/275/69 89/274/69 94/273/69
f 95/280/70 96/279/70 94/278/70 93/277/70
f 94/284/71 89/283/71 90/282/71 93/281/71
f 95/288/72 92/287/72 91/286/72 96/285/72
o head
v 0.3125 0.7864724155271074 -4.080890122368709
v 0.3125 0.6582148617799817 -4.433274855163424
v 0.3125 0.5515492603306302 -3.9953850865372913
v 0.3125 0.42329170658350446 -4.347769819332006
v 0.1875 0.6582148617799817 -4.433274855163424
v 0.1875 0.7864724155271074 -4.080890122368709
v 0.1875 0.42329170658350446 -4.347769819332006
v 0.1875 0.5515492603306302 -3.9953850865372913
vt 0.03125 0.9765625
vt 0.0234375 0.9765625
vt 0.0234375 0.9609375
vt 0.03125 0.9609375
vt 0.0546875 0.9765625
vt 0.03125 0.9765625
vt 0.03125 0.9609375
vt 0.0546875 0.9609375
vt 0.0625 0.9765625
vt 0.0546875 0.9765625
vt 0.0546875 0.9609375
vt 0.0625 0.9609375
vt 0.0234375 0.9765625
vt 0 0.9765625
vt 0 0.9609375
vt 0.0234375 0.9609375
vt 0.0234375 0.9765625
vt 0.03125 0.9765625
vt 0.03125 1
vt 0.0234375 1
vt 0.03125 1
vt 0.0390625 1
vt 0.0390625 0.9765625
vt 0.03125 0.9765625
vn 0 -0.34202014332566877 -0.9396926207859084
vn 1 0 0
vn 0 0.34202014332566877 0.9396926207859084
vn -1 0 0
vn 0 0.9396926207859084 -0.34202014332566877
vn 0 -0.9396926207859084 0.34202014332566877
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 100/292/73 103/291/73 101/290/73 98/289/73
f 99/296/74 100/295/74 98/294/74 97/293/74
f 104/300/75 99/299/75 97/298/75 102/297/75
f 103/304/76 104/303/76 102/302/76 101/301/76
f 102/308/77 97/307/77 98/306/77 101/305/77
f 103/312/78 100/311/78 99/310/78 104/309/78
o head
v 0.3125 -0.16962986282205206 -5.063119567730405
v 0.3125 -0.2551348986534694 -5.298042722926883
v 0.3125 -0.28709144042029067 -5.020367049814697
v 0.3125 -0.372596476251708 -5.255290205011175
v 0.1875 -0.2551348986534694 -5.298042722926883
v 0.1875 -0.16962986282205206 -5.063119567730405
v 0.1875 -0.372596476251708 -5.255290205011175
v 0.1875 -0.28709144042029067 -5.020367049814697
vt 0.4609375 0.984375
vt 0.453125 0.984375
vt 0.453125 0.9765625
vt 0.4609375 0.9765625
vt 0.4765625 0.984375
vt 0.4609375 0.984375
vt 0.4609375 0.9765625
vt 0.4765625 0.9765625
vt 0.484375 0.984375
vt 0.4765625 0.984375
vt 0.4765625 0.9765625
vt 0.484375 0.9765625
vt 0.453125 0.984375
vt 0.4375 0.984375
vt 0.4375 0.9765625
vt 0.453125 0.9765625
vt 0.453125 0.984375
vt 0.4609375 0.984375
vt 0.4609375 1
vt 0.453125 1
vt 0.4609375 1
vt 0.46875 1
vt 0.46875 0.984375
vt 0.4609375 0.984375
vn 0 -0.34202014332566877 -0.9396926207859084
vn 1 0 0
vn 0 0.34202014332566877 0.9396926207859084
vn -1 0 0
vn 0 0.9396926207859084 -0.34202014332566877
vn 0 -0.9396926207859084 0.34202014332566877
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 108/316/79 111/315/79 109/314/79 106/313/79
f 107/320/80 108/319/80 106/318/80 105/317/80
f 112/324/81 107/323/81 105/322/81 110/321/81
f 111/328/82 112/327/82 110/326/82 109/325/82
f 110/332/83 105/331/83 106/330/83 109/329/83
f 111/336/84 108/335/84 107/334/84 112/333/84
o head
v -0.1875 0.7864724155271074 -4.080890122368709
v -0.1875 0.6582148617799817 -4.433274855163424
v -0.1875 0.5515492603306302 -3.9953850865372913
v -0.1875 0.42329170658350446 -4.347769819332006
v -0.3125 0.6582148617799817 -4.433274855163424
v -0.3125 0.7864724155271074 -4.080890122368709
v -0.3125 0.42329170658350446 -4.347769819332006
v -0.3125 0.5515492603306302 -3.9953850865372913
vt 0.0234375 0.9765625
vt 0.03125 0.9765625
vt 0.03125 0.9609375
vt 0.0234375 0.9609375
vt 0 0.9765625
vt 0.0234375 0.9765625
vt 0.0234375 0.9609375
vt 0 0.9609375
vt 0.0546875 0.9765625
vt 0.0625 0.9765625
vt 0.0625 0.9609375
vt 0.0546875 0.9609375
vt 0.03125 0.9765625
vt 0.0546875 0.9765625
vt 0.0546875 0.9609375
vt 0.03125 0.9609375
vt 0.03125 0.9765625
vt 0.0234375 0.9765625
vt 0.0234375 1
vt 0.03125 1
vt 0.0390625 1
vt 0.03125 1
vt 0.03125 0.9765625
vt 0.0390625 0.9765625
vn 0 -0.34202014332566877 -0.9396926207859084
vn 1 0 0
vn 0 0.34202014332566877 0.9396926207859084
vn -1 0 0
vn 0 0.9396926207859084 -0.34202014332566877
vn 0 -0.9396926207859084 0.34202014332566877
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 116/340/85 119/339/85 117/338/85 114/337/85
f 115/344/86 116/343/86 114/342/86 113/341/86
f 120/348/87 115/347/87 113/346/87 118/345/87
f 119/352/88 120/351/88 118/350/88 117/349/88
f 118/356/89 113/355/89 114/354/89 117/353/89
f 119/360/90 116/359/90 115/358/90 120/357/90
o head
v -0.1875 -0.16962986282205206 -5.063119567730405
v -0.1875 -0.2551348986534694 -5.298042722926883
v -0.1875 -0.28709144042029067 -5.020367049814697
v -0.1875 -0.372596476251708 -5.255290205011175
v -0.3125 -0.2551348986534694 -5.298042722926883
v -0.3125 -0.16962986282205206 -5.063119567730405
v -0.3125 -0.372596476251708 -5.255290205011175
v -0.3125 -0.28709144042029067 -5.020367049814697
vt 0.453125 0.984375
vt 0.4609375 0.984375
vt 0.4609375 0.9765625
vt 0.453125 0.9765625
vt 0.4375 0.984375
vt 0.453125 0.984375
vt 0.453125 0.9765625
vt 0.4375 0.9765625
vt 0.4765625 0.984375
vt 0.484375 0.984375
vt 0.484375 0.9765625
vt 0.4765625 0.9765625
vt 0.4609375 0.984375
vt 0.4765625 0.984375
vt 0.4765625 0.9765625
vt 0.4609375 0.9765625
vt 0.4609375 0.984375
vt 0.453125 0.984375
vt 0.453125 1
vt 0.4609375 1
vt 0.46875 1
vt 0.4609375 1
vt 0.4609375 0.984375
vt 0.46875 0.984375
vn 0 -0.34202014332566877 -0.9396926207859084
vn 1 0 0
vn 0 0.34202014332566877 0.9396926207859084
vn -1 0 0
vn 0 0.9396926207859084 -0.34202014332566877
vn 0 -0.9396926207859084 0.34202014332566877
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 124/364/91 127/363/91 125/362/91 122/361/91
f 123/368/92 124/367/92 122/366/92 121/365/92
f 128/372/93 123/371/93 121/370/93 126/369/93
f 127/376/94 128/375/94 126/374/94 125/373/94
f 126/380/95 121/379/95 122/378/95 125/377/95
f 127/384/96 124/383/96 123/382/96 128/381/96
o jaw
v 0.375 -0.3814550631514302 -4.318644081003175
v 0.375 -0.9550314995024762 -5.137796125292168
v 0.375 -0.5862430742236779 -4.175249971915413
v 0.375 -1.159819510574724 -4.994402016204406
v -0.375 -0.9550314995024762 -5.137796125292168
v -0.375 -0.3814550631514302 -4.318644081003175
v -0.375 -1.159819510574724 -4.994402016204406
v -0.375 -0.5862430742236779 -4.175249971915413
vt 0.75 0.68359375
vt 0.796875 0.68359375
vt 0.796875 0.66796875
vt 0.75 0.66796875
vt 0.6875 0.68359375
vt 0.75 0.68359375
vt 0.75 0.66796875
vt 0.6875 0.66796875
vt 0.859375 0.68359375
vt 0.90625 0.68359375
vt 0.90625 0.66796875
vt 0.859375 0.66796875
vt 0.796875 0.68359375
vt 0.859375 0.68359375
vt 0.859375 0.66796875
vt 0.796875 0.66796875
vt 0.796875 0.68359375
vt 0.75 0.68359375
vt 0.75 0.74609375
vt 0.796875 0.74609375
vt 0.84375 0.74609375
vt 0.796875 0.74609375
vt 0.796875 0.68359375
vt 0.84375 0.68359375
vn 0 -0.5735764363510462 -0.8191520442889919
vn 1 0 0
vn 0 0.5735764363510462 0.8191520442889919
vn -1 0 0
vn 0 0.8191520442889919 -0.5735764363510462
vn 0 -0.8191520442889919 0.5735764363510462
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 132/388/97 135/387/97 133/386/97 130/385/97
f 131/392/98 132/391/98 130/390/98 129/389/98
f 136/396/99 131/395/99 129/394/99 134/393/99
f 135/400/100 136/399/100 134/398/100 133/397/100
f 134/404/101 129/403/101 130/402/101 133/401/101
f 135/408/102 132/407/102 131/406/102 136/405/102
o body
v 0.75 1.25 3.5
v 0.75 1.25 -0.5
v 0.75 -0.25 3.5
v 0.75 -0.25 -0.5
v -0.75 1.25 -0.5
v -0.75 1.25 3.5
v -0.75 -0.25 -0.5
v -0.75 -0.25 3.5
vt 0.25 0.75
vt 0.34375 0.75
vt 0.34375 0.65625
vt 0.25 0.65625
vt 0 0.75
vt 0.25 0.75
vt 0.25 0.65625
vt 0 0.65625
vt 0.59375 0.75
vt 0.6875 0.75
vt 0.6875 0.65625
vt 0.59375 0.65625
vt 0.34375 0.75
vt 0.59375 0.75
vt 0.59375 0.65625
vt 0.34375 0.65625
vt 0.34375 0.75
vt 0.25 0.75
vt 0.25 1
vt 0.34375 1
vt 0.4375 1
vt 0.34375 1
vt 0.34375 0.75
vt 0.4375 0.75
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 140/412/103 143/411/103 141/410/103 138/409/103
f 139/416/104 140/415/104 138/414/104 137/413/104
f 144/420/105 139/419/105 137/418/105 142/417/105
f 143/424/106 144/423/106 142/422/106 141/421/106
f 142/428/107 137/427/107 138/426/107 141/425/107
f 143/432/108 140/431/108 139/430/108 144/429/108
o body
v 0.0625 1.625 0.625
v 0.0625 1.625 -0.125
v 0.0625 1.25 0.625
v 0.0625 1.25 -0.125
v -0.0625 1.625 -0.125
v -0.0625 1.625 0.625
v -0.0625 1.25 -0.125
v -0.0625 1.25 0.625
vt 0.90625 0.74609375
vt 0.9140625 0.74609375
vt 0.9140625 0.72265625
vt 0.90625 0.72265625
vt 0.859375 0.74609375
vt 0.90625 0.74609375
vt 0.90625 0.72265625
vt 0.859375 0.72265625
vt 0.9609375 0.74609375
vt 0.96875 0.74609375
vt 0.96875 0.72265625
vt 0.9609375 0.72265625
vt 0.9140625 0.74609375
vt 0.9609375 0.74609375
vt 0.9609375 0.72265625
vt 0.9140625 0.72265625
vt 0.9140625 0.74609375
vt 0.90625 0.74609375
vt 0.90625 0.79296875
vt 0.9140625 0.79296875
vt 0.921875 0.79296875
vt 0.9140625 0.79296875
vt 0.9140625 0.74609375
vt 0.921875 0.74609375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 148/436/109 151/435/109 149/434/109 146/433/109
f 147/440/110 148/439/110 146/438/110 145/437/110
f 152/444/111 147/443/111 145/442/111 150/441/111
f 151/448/112 152/447/112 150/446/112 149/445/112
f 150/452/113 145/451/113 146/450/113 149/449/113
f 151/456/114 148/455/114 147/454/114 152/453/114
o body
v 0.0625 1.625 1.875
v 0.0625 1.625 1.125
v 0.0625 1.25 1.875
v 0.0625 1.25 1.125
v -0.0625 1.625 1.125
v -0.0625 1.625 1.875
v -0.0625 1.25 1.125
v -0.0625 1.25 1.875
vt 0.90625 0.74609375
vt 0.9140625 0.74609375
vt 0.9140625 0.72265625
vt 0.90625 0.72265625
vt 0.859375 0.74609375
vt 0.90625 0.74609375
vt 0.90625 0.72265625
vt 0.859375 0.72265625
vt 0.9609375 0.74609375
vt 0.96875 0.74609375
vt 0.96875 0.72265625
vt 0.9609375 0.72265625
vt 0.9140625 0.74609375
vt 0.9609375 0.74609375
vt 0.9609375 0.72265625
vt 0.9140625 0.72265625
vt 0.9140625 0.74609375
vt 0.90625 0.74609375
vt 0.90625 0.79296875
vt 0.9140625 0.79296875
vt 0.921875 0.79296875
vt 0.9140625 0.79296875
vt 0.9140625 0.74609375
vt 0.921875 0.74609375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 156/460/115 159/459/115 157/458/115 154/457/115
f 155/464/116 156/463/116 154/462/116 153/461/116
f 160/468/117 155/467/117 153/466/117 158/465/117
f 159/472/118 160/471/118 158/470/118 157/469/118
f 158/476/119 153/475/119 154/474/119 157/473/119
f 159/480/120 156/479/120 155/478/120 160/477/120
o body
v 0.0625 1.625 3.125
v 0.0625 1.625 2.375
v 0.0625 1.25 3.125
v 0.0625 1.25 2.375
v -0.0625 1.625 2.375
v -0.0625 1.625 3.125
v -0.0625 1.25 2.375
v -0.0625 1.25 3.125
vt 0.90625 0.74609375
vt 0.9140625 0.74609375
vt 0.9140625 0.72265625
vt 0.90625 0.72265625
vt 0.859375 0.74609375
vt 0.90625 0.74609375
vt 0.90625 0.72265625
vt 0.859375 0.72265625
vt 0.9609375 0.74609375
vt 0.96875 0.74609375
vt 0.96875 0.72265625
vt 0.9609375 0.72265625
vt 0.9140625 0.74609375
vt 0.9609375 0.74609375
vt 0.9609375 0.72265625
vt 0.9140625 0.72265625
vt 0.9140625 0.74609375
vt 0.90625 0.74609375
vt 0.90625 0.79296875
vt 0.9140625 0.79296875
vt 0.921875 0.79296875
vt 0.9140625 0.79296875
vt 0.9140625 0.74609375
vt 0.921875 0.74609375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 164/484/121 167/483/121 165/482/121 162/481/121
f 163/488/122 164/487/122 162/486/122 161/485/122
f 168/492/123 163/491/123 161/490/123 166/489/123
f 167/496/124 168/495/124 166/494/124 165/493/124
f 166/500/125 161/499/125 162/498/125 165/497/125
f 167/504/126 164/503/126 163/502/126 168/501/126
o wing
v 4.0582975240428985 2.024698766671211 0.9789705600873082
v 4.143802559874316 2.039775611474734 0.48656668358120414
v 4.1451216128763635 1.5322948901651068 0.9789705600873082
v 4.230626648707781 1.5473717349686296 0.48656668358120414
v 0.7493404734989761 1.4412403606548134 -0.12120193825305198
v 0.6638354376675588 1.4261635158512906 0.37120193825305203
v 0.8361645623324412 0.9488364841487094 -0.12120193825305198
v 0.7506595265010241 0.9337596393451866 0.37120193825305203
vt 0.46875 0.625
vt 0.6875 0.625
vt 0.6875 0.59375
vt 0.46875 0.59375
vt 0.4375 0.625
vt 0.46875 0.625
vt 0.46875 0.59375
vt 0.4375 0.59375
vt 0.71875 0.625
vt 0.9375 0.625
vt 0.9375 0.59375
vt 0.71875 0.59375
vt 0.6875 0.625
vt 0.71875 0.625
vt 0.71875 0.59375
vt 0.6875 0.59375
vt 0.6875 0.625
vt 0.46875 0.625
vt 0.46875 0.65625
vt 0.6875 0.65625
vt 0.90625 0.65625
vt 0.6875 0.65625
vt 0.6875 0.625
vt 0.90625 0.625
vn 0.17101007166283433 0.030153689607045803 -0.984807753012208
vn 0.9698463103929543 0.17101007166283436 0.17364817766693036
vn -0.17101007166283433 -0.030153689607045803 0.984807753012208
vn -0.9698463103929543 -0.17101007166283436 -0.17364817766693036
vn -0.17364817766693033 0.984807753012208 0
vn 0.17364817766693033 -0.984807753012208 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 172/508/127 175/507/127 173/506/127 170/505/127
f 171/512/128 172/511/128 170/510/128 169/509/128
f 176/516/129 171/515/129 169/514/129 174/513/129
f 175/520/130 176/519/130 174/518/130 173/517/130
f 174/524/131 169/523/131 170/522/131 173/521/131
f 175/528/132 172/527/132 171/526/132 176/525/132
o wing
v 3.5249413191357313 1.677431666078797 4.303420761460185
v 4.123690332545229 1.7830072718154657 0.8553626162261914
v 3.525158379357814 1.6762006563875311 4.303420761460185
v 4.123907392767313 1.7817762621242004 0.8553626162261914
v 0.7280159382818976 1.184258258405967 0.24737693416985151
v 0.12926692487239855 1.0786826526692979 3.695435079403845
v 0.728232998503981 1.1830272487147016 0.24737693416985151
v 0.12948398509448222 1.0774516429780323 3.695435079403845
vt 0 0.4375
vt 0.21875 0.4375
vt 0.21875 0.4375
vt 0 0.4375
vt -0.21875 0.4375
vt 0 0.4375
vt 0 0.4375
vt -0.21875 0.4375
vt 0.4375 0.4375
vt 0.65625 0.4375
vt 0.65625 0.4375
vt 0.4375 0.4375
vt 0.21875 0.4375
vt 0.4375 0.4375
vt 0.4375 0.4375
vt 0.21875 0.4375
vt 0.21875 0.4375
vt 0 0.4375
vt 0 0.65625
vt 0.21875 0.65625
vt 0.4375 0.65625
vt 0.21875 0.65625
vt 0.21875 0.4375
vt 0.4375 0.4375
vn 0.17101007166283433 0.030153689607045803 -0.984807753012208
vn 0.9698463103929543 0.17101007166283436 0.17364817766693036
vn -0.17101007166283433 -0.030153689607045803 0.984807753012208
vn -0.9698463103929543 -0.17101007166283436 -0.17364817766693036
vn -0.17364817766693033 0.984807753012208 0
vn 0.17364817766693033 -0.984807753012208 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 180/532/133 183/531/133 181/530/133 178/529/133
f 179/536/134 180/535/134 178/534/134 177/533/134
f 184/540/135 179/539/135 177/538/135 182/537/135
f 183/544/136 184/543/136 182/542/136 181/541/136
f 182/548/137 177/547/137 178/546/137 181/545/137
f 183/552/138 180/551/138 179/550/138 184/549/138
o wingtip
v 7.541772295535269 1.2888090706299558 1.4344091768703897
v 7.584524813450978 1.2963474930317171 1.1882072386173377
v 7.499639529805786 1.0428327037253111 1.4195613832169185
v 7.542392047721496 1.0503711261270725 1.1733594449638665
v 4.186904728197936 1.912792645473123 0.6170915495344658
v 4.144152210282227 1.9052542230713616 0.8632934877875178
v 4.144771962468453 1.6668162785684784 0.6022437558809948
v 4.102019444552744 1.659277856166717 0.8484456941340466
vt 0.453125 0.453125
vt 0.671875 0.453125
vt 0.671875 0.4375
vt 0.453125 0.4375
vt 0.4375 0.453125
vt 0.453125 0.453125
vt 0.453125 0.4375
vt 0.4375 0.4375
vt 0.6875 0.453125
vt 0.90625 0.453125
vt 0.90625 0.4375
vt 0.6875 0.4375
vt 0.671875 0.453125
vt 0.6875 0.453125
vt 0.6875 0.4375
vt 0.671875 0.4375
vt 0.671875 0.453125
vt 0.453125 0.453125
vt 0.453125 0.46875
vt 0.671875 0.46875
vt 0.890625 0.46875
vt 0.671875 0.46875
vt 0.671875 0.453125
vt 0.890625 0.453125
vn 0.17101007166283436 0.0301536896070458 -0.9848077530122081
vn 0.9707485957865836 -0.1761271864118303 0.16317591116653485
vn -0.17101007166283436 -0.0301536896070458 0.9848077530122081
vn -0.9707485957865836 0.1761271864118303 -0.16317591116653485
vn 0.16853106291793427 0.9839054676185789 0.059391174613884684
vn -0.16853106291793427 -0.9839054676185789 -0.059391174613884684
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 188/556/139 191/555/139 189/554/139 186/553/139
f 187/560/140 188/559/140 186/558/140 185/557/140
f 192/564/141 187/563/141 185/562/141 190/561/141
f 191/568/142 192/567/142 190/566/142 189/565/142
f 190/572/143 185/571/143 186/570/143 189/569/143
f 191/576/144 188/575/144 187/574/144 192/573/144
o wingtip
v 6.922775830342509 1.060768988922723 4.874567024860628
v 7.521524843752008 1.1663445946593922 1.4265088796266343
v 6.922565166513862 1.0595391070881996 4.874492785892361
v 7.521314179923362 1.165114712824869 1.426434640658367
v 4.122691322754232 1.7830099060838132 0.855189220654804
v 3.523942309344733 1.677434300347144 4.303247365888798
v 4.122480658925585 1.7817800242492896 0.8551149816865367
v 3.5237316455160856 1.6762044185126204 4.30317312692053
vt 0 0.21875
vt 0.21875 0.21875
vt 0.21875 0.21875
vt 0 0.21875
vt -0.21875 0.21875
vt 0 0.21875
vt 0 0.21875
vt -0.21875 0.21875
vt 0.4375 0.21875
vt 0.65625 0.21875
vt 0.65625 0.21875
vt 0.4375 0.21875
vt 0.21875 0.21875
vt 0.4375 0.21875
vt 0.4375 0.21875
vt 0.21875 0.21875
vt 0.21875 0.21875
vt 0 0.21875
vt 0 0.4375
vt 0.21875 0.4375
vt 0.4375 0.4375
vt 0.21875 0.4375
vt 0.21875 0.21875
vt 0.4375 0.21875
vn 0.17101007166283436 0.0301536896070458 -0.9848077530122081
vn 0.9707485957865836 -0.1761271864118303 0.16317591116653485
vn -0.17101007166283436 -0.0301536896070458 0.9848077530122081
vn -0.9707485957865836 0.1761271864118303 -0.16317591116653485
vn 0.16853106291793427 0.9839054676185789 0.059391174613884684
vn -0.16853106291793427 -0.9839054676185789 -0.059391174613884684
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 196/580/145 199/579/145 197/578/145 194/577/145
f 195/584/146 196/583/146 194/582/146 193/581/146
f 200/588/147 195/587/147 193/586/147 198/585/147
f 199/592/148 200/591/148 198/590/148 197/589/148
f 198/596/149 193/595/149 194/594/149 197/593/149
f 199/600/150 196/599/150 195/598/150 200/597/150
o wing1
v -0.6638354376675588 1.4261635158512906 0.37120193825305203
v -0.749340473498976 1.4412403606548134 -0.12120193825305198
v -0.7506595265010241 0.9337596393451866 0.37120193825305203
v -0.8361645623324412 0.9488364841487094 -0.12120193825305198
v -4.143802559874316 2.039775611474734 0.48656668358120414
v -4.0582975240428985 2.024698766671211 0.9789705600873082
v -4.230626648707781 1.5473717349686296 0.48656668358120414
v -4.1451216128763635 1.5322948901651068 0.9789705600873082
vt 0.6875 0.625
vt 0.46875 0.625
vt 0.46875 0.59375
vt 0.6875 0.59375
vt 0.71875 0.625
vt 0.6875 0.625
vt 0.6875 0.59375
vt 0.71875 0.59375
vt 0.9375 0.625
vt 0.71875 0.625
vt 0.71875 0.59375
vt 0.9375 0.59375
vt 0.46875 0.625
vt 0.4375 0.625
vt 0.4375 0.59375
vt 0.46875 0.59375
vt 0.46875 0.625
vt 0.6875 0.625
vt 0.6875 0.65625
vt 0.46875 0.65625
vt 0.6875 0.65625
vt 0.90625 0.65625
vt 0.90625 0.625
vt 0.6875 0.625
vn -0.17101007166283433 0.030153689607045803 -0.984807753012208
vn 0.9698463103929543 -0.17101007166283436 -0.17364817766693036
vn 0.17101007166283433 -0.030153689607045803 0.984807753012208
vn -0.9698463103929543 0.17101007166283436 0.17364817766693036
vn 0.17364817766693033 0.984807753012208 0
vn -0.17364817766693033 -0.984807753012208 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 204/604/151 207/603/151 205/602/151 202/601/151
f 203/608/152 204/607/152 202/606/152 201/605/152
f 208/612/153 203/611/153 201/610/153 206/609/153
f 207/616/154 208/615/154 206/614/154 205/613/154
f 206/620/155 201/619/155 202/618/155 205/617/155
f 207/624/156 204/623/156 203/622/156 208/621/156
o wing1
v -0.1292669248723986 1.0786826526692979 3.695435079403845
v -0.7280159382818975 1.184258258405967 0.24737693416985151
v -0.12948398509448228 1.0774516429780323 3.695435079403845
v -0.7282329985039812 1.1830272487147016 0.24737693416985151
v -4.123690332545229 1.7830072718154657 0.8553626162261914
v -3.524941319135731 1.677431666078797 4.303420761460185
v -4.123907392767313 1.7817762621242004 0.8553626162261914
v -3.5251583793578143 1.6762006563875311 4.303420761460185
vt 0.21875 0.4375
vt 0 0.4375
vt 0 0.4375
vt 0.21875 0.4375
vt 0.4375 0.4375
vt 0.21875 0.4375
vt 0.21875 0.4375
vt 0.4375 0.4375
vt 0.65625 0.4375
vt 0.4375 0.4375
vt 0.4375 0.4375
vt 0.65625 0.4375
vt 0 0.4375
vt -0.21875 0.4375
vt -0.21875 0.4375
vt 0 0.4375
vt 0 0.4375
vt 0.21875 0.4375
vt 0.21875 0.65625
vt 0 0.65625
vt 0.21875 0.65625
vt 0.4375 0.65625
vt 0.4375 0.4375
vt 0.21875 0.4375
vn -0.17101007166283433 0.030153689607045803 -0.984807753012208
vn 0.9698463103929543 -0.17101007166283436 -0.17364817766693036
vn 0.17101007166283433 -0.030153689607045803 0.984807753012208
vn -0.9698463103929543 0.17101007166283436 0.17364817766693036
vn 0.17364817766693033 0.984807753012208 0
vn -0.17364817766693033 -0.984807753012208 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 212/628/157 215/627/157 213/626/157 210/625/157
f 211/632/158 212/631/158 210/630/158 209/629/158
f 216/636/159 211/635/159 209/634/159 214/633/159
f 215/640/160 216/639/160 214/638/160 213/637/160
f 214/644/161 209/643/161 210/642/161 213/641/161
f 215/648/162 212/647/162 211/646/162 216/645/162
o wingtip1
v -4.144152210282227 1.9052542230713616 0.8632934877875178
v -4.186904728197936 1.912792645473123 0.6170915495344658
v -4.102019444552744 1.659277856166717 0.8484456941340466
v -4.144771962468453 1.6668162785684784 0.6022437558809948
v -7.584524813450978 1.2963474930317171 1.1882072386173377
v -7.541772295535269 1.2888090706299558 1.4344091768703897
v -7.542392047721495 1.0503711261270725 1.1733594449638665
v -7.499639529805786 1.0428327037253111 1.4195613832169185
vt 0.671875 0.453125
vt 0.453125 0.453125
vt 0.453125 0.4375
vt 0.671875 0.4375
vt 0.6875 0.453125
vt 0.671875 0.453125
vt 0.671875 0.4375
vt 0.6875 0.4375
vt 0.90625 0.453125
vt 0.6875 0.453125
vt 0.6875 0.4375
vt 0.90625 0.4375
vt 0.453125 0.453125
vt 0.4375 0.453125
vt 0.4375 0.4375
vt 0.453125 0.4375
vt 0.453125 0.453125
vt 0.671875 0.453125
vt 0.671875 0.46875
vt 0.453125 0.46875
vt 0.671875 0.46875
vt 0.890625 0.46875
vt 0.890625 0.453125
vt 0.671875 0.453125
vn -0.17101007166283436 0.0301536896070458 -0.9848077530122081
vn 0.9707485957865836 0.1761271864118303 -0.16317591116653485
vn 0.17101007166283436 -0.0301536896070458 0.9848077530122081
vn -0.9707485957865836 -0.1761271864118303 0.16317591116653485
vn -0.16853106291793427 0.9839054676185789 0.059391174613884684
vn 0.16853106291793427 -0.9839054676185789 -0.059391174613884684
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 220/652/163 223/651/163 221/650/163 218/649/163
f 219/656/164 220/655/164 218/654/164 217/653/164
f 224/660/165 219/659/165 217/658/165 222/657/165
f 223/664/166 224/663/166 222/662/166 221/661/166
f 222/668/167 217/667/167 218/666/167 221/665/167
f 223/672/168 220/671/168 219/670/168 224/669/168
o wingtip1
v -3.523942309344733 1.677434300347144 4.303247365888798
v -4.122691322754232 1.7830099060838132 0.855189220654804
v -3.5237316455160856 1.6762044185126204 4.30317312692053
v -4.122480658925585 1.7817800242492896 0.8551149816865367
v -7.521524843752008 1.1663445946593922 1.4265088796266343
v -6.922775830342509 1.060768988922723 4.874567024860628
v -7.521314179923361 1.165114712824869 1.426434640658367
v -6.922565166513862 1.0595391070881996 4.874492785892361
vt 0.21875 0.21875
vt 0 0.21875
vt 0 0.21875
vt 0.21875 0.21875
vt 0.4375 0.21875
vt 0.21875 0.21875
vt 0.21875 0.21875
vt 0.4375 0.21875
vt 0.65625 0.21875
vt 0.4375 0.21875
vt 0.4375 0.21875
vt 0.65625 0.21875
vt 0 0.21875
vt -0.21875 0.21875
vt -0.21875 0.21875
vt 0 0.21875
vt 0 0.21875
vt 0.21875 0.21875
vt 0.21875 0.4375
vt 0 0.4375
vt 0.21875 0.4375
vt 0.4375 0.4375
vt 0.4375 0.21875
vt 0.21875 0.21875
vn -0.17101007166283436 0.0301536896070458 -0.9848077530122081
vn 0.9707485957865836 0.1761271864118303 -0.16317591116653485
vn 0.17101007166283436 -0.0301536896070458 0.9848077530122081
vn -0.9707485957865836 -0.1761271864118303 0.16317591116653485
vn -0.16853106291793427 0.9839054676185789 0.059391174613884684
vn 0.16853106291793427 -0.9839054676185789 -0.059391174613884684
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 228/676/169 231/675/169 229/674/169 226/673/169
f 227/680/170 228/679/170 226/678/170 225/677/170
f 232/684/171 227/683/171 225/682/171 230/681/171
f 231/688/172 232/687/172 230/686/172 229/685/172
f 230/692/173 225/691/173 226/690/173 229/689/173
f 231/696/174 228/695/174 227/694/174 232/693/174
o rearleg
v 1.5 1.0580127018922192 2.6584936490538906
v 1.5 0.1919872981077808 2.1584936490538906
v 1.5 0.05801270189221919 4.390544456622768
v 1.5 -0.8080127018922196 3.8905444566227683
v 0.5 0.1919872981077808 2.1584936490538906
v 0.5 1.0580127018922192 2.6584936490538906
v 0.5 -0.8080127018922196 3.8905444566227683
v 0.5 0.05801270189221919 4.390544456622768
vt 0.0625 0.9375
vt 0.125 0.9375
vt 0.125 0.8125
vt 0.0625 0.8125
vt 0 0.9375
vt 0.0625 0.9375
vt 0.0625 0.8125
vt 0 0.8125
vt 0.1875 0.9375
vt 0.25 0.9375
vt 0.25 0.8125
vt 0.1875 0.8125
vt 0.125 0.9375
vt 0.1875 0.9375
vt 0.1875 0.8125
vt 0.125 0.8125
vt 0.125 0.9375
vt 0.0625 0.9375
vt 0.0625 1
vt 0.125 1
vt 0.1875 1
vt 0.125 1
vt 0.125 0.9375
vt 0.1875 0.9375
vn 0 -0.8660254037844386 -0.5000000000000001
vn 1 0 0
vn 0 0.8660254037844386 0.5000000000000001
vn -1 0 0
vn 0 0.5000000000000001 -0.8660254037844386
vn 0 -0.5000000000000001 0.8660254037844386
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 236/700/175 239/699/175 237/698/175 234/697/175
f 235/704/176 236/703/176 234/702/176 233/701/176
f 240/708/177 235/707/177 233/706/177 238/705/177
f 239/712/178 240/711/178 238/710/178 237/709/178
f 238/716/179 233/715/179 234/714/179 237/713/179
f 239/720/180 236/719/180 235/718/180 240/717/180
o rearlegtip
v 1.375 -0.009562569109802244 4.19903062623141
v 1.375 -0.7567085926786117 4.133663819170667
v 1.375 -0.18387405460511896 6.191420022414902
v 1.375 -0.9310200781739284 6.126053215354158
v 0.625 -0.7567085926786117 4.133663819170667
v 0.625 -0.009562569109802244 4.19903062623141
v 0.625 -0.9310200781739284 6.126053215354158
v 0.625 -0.18387405460511896 6.191420022414902
vt 0.8125 0.953125
vt 0.859375 0.953125
vt 0.859375 0.828125
vt 0.8125 0.828125
vt 0.765625 0.953125
vt 0.8125 0.953125
vt 0.8125 0.828125
vt 0.765625 0.828125
vt 0.90625 0.953125
vt 0.953125 0.953125
vt 0.953125 0.828125
vt 0.90625 0.828125
vt 0.859375 0.953125
vt 0.90625 0.953125
vt 0.90625 0.828125
vt 0.859375 0.828125
vt 0.859375 0.953125
vt 0.8125 0.953125
vt 0.8125 1
vt 0.859375 1
vt 0.90625 1
vt 0.859375 1
vt 0.859375 0.953125
vt 0.90625 0.953125
vn 0 -0.9961946980917455 -0.0871557427476583
vn 1 0 0
vn 0 0.9961946980917455 0.0871557427476583
vn -1 0 0
vn 0 0.0871557427476583 -0.9961946980917455
vn 0 -0.0871557427476583 0.9961946980917455
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 244/724/181 247/723/181 245/722/181 242/721/181
f 243/728/182 244/727/182 242/726/182 241/725/182
f 248/732/183 243/731/183 241/730/183 246/729/183
f 247/736/184 248/735/184 246/734/184 245/733/184
f 246/740/185 241/739/185 242/738/185 245/737/185
f 247/744/186 244/743/186 243/742/186 248/741/186
o rearfoot
v 1.5625 -0.42819812424051307 5.992592482541166
v 1.5625 -1.5772647889189804 6.956773897070976
v 1.5625 -0.187152770608062 6.279859148710783
v 1.5625 -1.3362194352865284 7.244040563240592
v 0.4375 -1.5772647889189804 6.956773897070976
v 0.4375 -0.42819812424051307 5.992592482541166
v 0.4375 -1.3362194352865284 7.244040563240592
v 0.4375 -0.187152770608062 6.279859148710783
vt 0.53125 0.90625
vt 0.6015625 0.90625
vt 0.6015625 0.8828125
vt 0.53125 0.8828125
vt 0.4375 0.90625
vt 0.53125 0.90625
vt 0.53125 0.8828125
vt 0.4375 0.8828125
vt 0.6953125 0.90625
vt 0.765625 0.90625
vt 0.765625 0.8828125
vt 0.6953125 0.8828125
vt 0.6015625 0.90625
vt 0.6953125 0.90625
vt 0.6953125 0.8828125
vt 0.6015625 0.8828125
vt 0.6015625 0.90625
vt 0.53125 0.90625
vt 0.53125 1
vt 0.6015625 1
vt 0.671875 1
vt 0.6015625 1
vt 0.6015625 0.90625
vt 0.671875 0.90625
vn 0 -0.766044443118978 0.6427876096865393
vn 1 0 0
vn 0 0.766044443118978 -0.6427876096865393
vn -1 0 0
vn 0 -0.6427876096865393 -0.766044443118978
vn 0 0.6427876096865393 0.766044443118978
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 252/748/187 255/747/187 253/746/187 250/745/187
f 251/752/188 252/751/188 250/750/188 249/749/188
f 256/756/189 251/755/189 249/754/189 254/753/189
f 255/760/190 256/759/190 254/758/190 253/757/190
f 254/764/191 249/763/191 250/762/191 253/761/191
f 255/768/192 252/767/192 251/766/192 256/765/192
o rearleg1
v -0.5 1.0580127018922192 2.6584936490538906
v -0.5 0.1919872981077808 2.1584936490538906
v -0.5 0.05801270189221919 4.390544456622768
v -0.5 -0.8080127018922196 3.8905444566227683
v -1.5 0.1919872981077808 2.1584936490538906
v -1.5 1.0580127018922192 2.6584936490538906
v -1.5 -0.8080127018922196 3.8905444566227683
v -1.5 0.05801270189221919 4.390544456622768
vt 0.125 0.9375
vt 0.0625 0.9375
vt 0.0625 0.8125
vt 0.125 0.8125
vt 0.1875 0.9375
vt 0.125 0.9375
vt 0.125 0.8125
vt 0.1875 0.8125
vt 0.25 0.9375
vt 0.1875 0.9375
vt 0.1875 0.8125
vt 0.25 0.8125
vt 0.0625 0.9375
vt 0 0.9375
vt 0 0.8125
vt 0.0625 0.8125
vt 0.0625 0.9375
vt 0.125 0.9375
vt 0.125 1
vt 0.0625 1
vt 0.125 1
vt 0.1875 1
vt 0.1875 0.9375
vt 0.125 0.9375
vn 0 -0.8660254037844386 -0.5000000000000001
vn 1 0 0
vn 0 0.8660254037844386 0.5000000000000001
vn -1 0 0
vn 0 0.5000000000000001 -0.8660254037844386
vn 0 -0.5000000000000001 0.8660254037844386
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 260/772/193 263/771/193 261/770/193 258/769/193
f 259/776/194 260/775/194 258/774/194 257/773/194
f 264/780/195 259/779/195 257/778/195 262/777/195
f 263/784/196 264/783/196 262/782/196 261/781/196
f 262/788/197 257/787/197 258/786/197 261/785/197
f 263/792/198 260/791/198 259/790/198 264/789/198
o rearlegtip
v -0.625 -0.009562569109802244 4.19903062623141
v -0.625 -0.7567085926786117 4.133663819170667
v -0.625 -0.18387405460511896 6.191420022414902
v -0.625 -0.9310200781739284 6.126053215354158
v -1.375 -0.7567085926786117 4.133663819170667
v -1.375 -0.009562569109802244 4.19903062623141
v -1.375 -0.9310200781739284 6.126053215354158
v -1.375 -0.18387405460511896 6.191420022414902
vt 0.859375 0.953125
vt 0.8125 0.953125
vt 0.8125 0.828125
vt 0.859375 0.828125
vt 0.90625 0.953125
vt 0.859375 0.953125
vt 0.859375 0.828125
vt 0.90625 0.828125
vt 0.953125 0.953125
vt 0.90625 0.953125
vt 0.90625 0.828125
vt 0.953125 0.828125
vt 0.8125 0.953125
vt 0.765625 0.953125
vt 0.765625 0.828125
vt 0.8125 0.828125
vt 0.8125 0.953125
vt 0.859375 0.953125
vt 0.859375 1
vt 0.8125 1
vt 0.859375 1
vt 0.90625 1
vt 0.90625 0.953125
vt 0.859375 0.953125
vn 0 -0.9961946980917455 -0.0871557427476583
vn 1 0 0
vn 0 0.9961946980917455 0.0871557427476583
vn -1 0 0
vn 0 0.0871557427476583 -0.9961946980917455
vn 0 -0.0871557427476583 0.9961946980917455
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 268/796/199 271/795/199 269/794/199 266/793/199
f 267/800/200 268/799/200 266/798/200 265/797/200
f 272/804/201 267/803/201 265/802/201 270/801/201
f 271/808/202 272/807/202 270/806/202 269/805/202
f 270/812/203 265/811/203 266/810/203 269/809/203
f 271/816/204 268/815/204 267/814/204 272/813/204
o rearfoot
v -0.4375 -0.42819812424051307 5.992592482541166
v -0.4375 -1.5772647889189804 6.956773897070976
v -0.4375 -0.187152770608062 6.279859148710783
v -0.4375 -1.3362194352865284 7.244040563240592
v -1.5625 -1.5772647889189804 6.956773897070976
v -1.5625 -0.42819812424051307 5.992592482541166
v -1.5625 -1.3362194352865284 7.244040563240592
v -1.5625 -0.187152770608062 6.279859148710783
vt 0.6015625 0.90625
vt 0.53125 0.90625
vt 0.53125 0.8828125
vt 0.6015625 0.8828125
vt 0.6953125 0.90625
vt 0.6015625 0.90625
vt 0.6015625 0.8828125
vt 0.6953125 0.8828125
vt 0.765625 0.90625
vt 0.6953125 0.90625
vt 0.6953125 0.8828125
vt 0.765625 0.8828125
vt 0.53125 0.90625
vt 0.4375 0.90625
vt 0.4375 0.8828125
vt 0.53125 0.8828125
vt 0.53125 0.90625
vt 0.6015625 0.90625
vt 0.6015625 1
vt 0.53125 1
vt 0.6015625 1
vt 0.671875 1
vt 0.671875 0.90625
vt 0.6015625 0.90625
vn 0 -0.766044443118978 0.6427876096865393
vn 1 0 0
vn 0 0.766044443118978 -0.6427876096865393
vn -1 0 0
vn 0 -0.6427876096865393 -0.766044443118978
vn 0 0.6427876096865393 0.766044443118978
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 276/820/205 279/819/205 277/818/205 274/817/205
f 275/824/206 276/823/206 274/822/206 273/821/206
f 280/828/207 275/827/207 273/826/207 278/825/207
f 279/832/208 280/831/208 278/830/208 277/829/208
f 278/836/209 273/835/209 274/834/209 277/833/209
f 279/840/210 276/839/210 275/838/210 280/837/210
o frontleg
v 1 0.5822315121943373 0.004077618676012307
v 1 0.1290776186760123 -0.20723151219433733
v 1 -0.05169588041671158 1.3635392992309874
v 1 -0.5048497739350366 1.1522301683606377
v 0.5 0.1290776186760123 -0.20723151219433733
v 0.5 0.5822315121943373 0.004077618676012307
v 0.5 -0.5048497739350366 1.1522301683606377
v 0.5 -0.05169588041671158 1.3635392992309874
vt 0.46875 0.5625
vt 0.5 0.5625
vt 0.5 0.46875
vt 0.46875 0.46875
vt 0.4375 0.5625
vt 0.46875 0.5625
vt 0.46875 0.46875
vt 0.4375 0.46875
vt 0.53125 0.5625
vt 0.5625 0.5625
vt 0.5625 0.46875
vt 0.53125 0.46875
vt 0.5 0.5625
vt 0.53125 0.5625
vt 0.53125 0.46875
vt 0.5 0.46875
vt 0.5 0.5625
vt 0.46875 0.5625
vt 0.46875 0.59375
vt 0.5 0.59375
vt 0.53125 0.59375
vt 0.5 0.59375
vt 0.5 0.5625
vt 0.53125 0.5625
vn 0 -0.90630778703665 -0.4226182617406993
vn 1 0 0
vn 0 0.90630778703665 0.4226182617406993
vn -1 0 0
vn 0 0.4226182617406993 -0.90630778703665
vn 0 -0.4226182617406993 0.90630778703665
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 284/844/211 287/843/211 285/842/211 282/841/211
f 283/848/212 284/847/212 282/846/212 281/845/212
f 288/852/213 283/851/213 281/850/213 286/849/213
f 287/856/214 288/855/214 286/854/214 285/853/214
f 286/860/215 281/859/215 282/858/215 285/857/215
f 287/864/216 284/863/216 283/862/216 288/861/216
o frontlegtip
v 0.9375 -0.10149613187923717 1.346273081444131
v 0.9375 -0.3666611748241926 1.0811080384991758
v 0.9375 -1.1621563036590583 2.4069332532239525
v 0.9375 -1.4273213466040136 2.141768210278997
v 0.5625 -0.3666611748241926 1.0811080384991758
v 0.5625 -0.10149613187923717 1.346273081444131
v 0.5625 -1.4273213466040136 2.141768210278997
v 0.5625 -1.1621563036590583 2.4069332532239525
vt 0.90625 0.4375
vt 0.9296875 0.4375
vt 0.9296875 0.34375
vt 0.90625 0.34375
vt 0.8828125 0.4375
vt 0.90625 0.4375
vt 0.90625 0.34375
vt 0.8828125 0.34375
vt 0.953125 0.4375
vt 0.9765625 0.4375
vt 0.9765625 0.34375
vt 0.953125 0.34375
vt 0.9296875 0.4375
vt 0.953125 0.4375
vt 0.953125 0.34375
vt 0.9296875 0.34375
vt 0.9296875 0.4375
vt 0.90625 0.4375
vt 0.90625 0.4609375
vt 0.9296875 0.4609375
vt 0.953125 0.4609375
vt 0.9296875 0.4609375
vt 0.9296875 0.4375
vt 0.953125 0.4375
vn 0 -0.7071067811865477 -0.7071067811865474
vn 1 0 0
vn 0 0.7071067811865477 0.7071067811865474
vn -1 0 0
vn 0 0.7071067811865474 -0.7071067811865477
vn 0 -0.7071067811865474 0.7071067811865477
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 292/868/217 295/867/217 293/866/217 290/865/217
f 291/872/218 292/871/218 290/870/218 289/869/218
f 296/876/219 291/875/219 289/874/219 294/873/219
f 295/880/220 296/879/220 294/878/220 293/877/220
f 294/884/221 289/883/221 290/882/221 293/881/221
f 295/888/222 292/887/222 291/886/222 296/885/222
o frontfoot
v 1 -1.0005446513073766 2.2301565579273155
v 1 -2.0005446513073766 2.2301565579273155
v 1 -1.0005446513073766 2.4801565579273155
v 1 -2.0005446513073766 2.4801565579273155
v 0.5 -2.0005446513073766 2.2301565579273155
v 0.5 -1.0005446513073766 2.2301565579273155
v 0.5 -2.0005446513073766 2.4801565579273155
v 0.5 -1.0005446513073766 2.4801565579273155
vt 0.625 0.53125
vt 0.65625 0.53125
vt 0.65625 0.515625
vt 0.625 0.515625
vt 0.5625 0.53125
vt 0.625 0.53125
vt 0.625 0.515625
vt 0.5625 0.515625
vt 0.71875 0.53125
vt 0.75 0.53125
vt 0.75 0.515625
vt 0.71875 0.515625
vt 0.65625 0.53125
vt 0.71875 0.53125
vt 0.71875 0.515625
vt 0.65625 0.515625
vt 0.65625 0.53125
vt 0.625 0.53125
vt 0.625 0.59375
vt 0.65625 0.59375
vt 0.6875 0.59375
vt 0.65625 0.59375
vt 0.65625 0.53125
vt 0.6875 0.53125
vn 0 -1 2.7755575615628914e-16
vn 1 0 0
vn 0 1 -2.7755575615628914e-16
vn -1 0 0
vn 0 -2.7755575615628914e-16 -1
vn 0 2.7755575615628914e-16 1
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 300/892/223 303/891/223 301/890/223 298/889/223
f 299/896/224 300/895/224 298/894/224 297/893/224
f 304/900/225 299/899/225 297/898/225 302/897/225
f 303/904/226 304/903/226 302/902/226 301/901/226
f 302/908/227 297/907/227 298/906/227 301/905/227
f 303/912/228 300/911/228 299/910/228 304/909/228
o frontleg1
v -0.5 0.5822315121943373 0.004077618676012307
v -0.5 0.1290776186760123 -0.20723151219433733
v -0.5 -0.05169588041671158 1.3635392992309874
v -0.5 -0.5048497739350366 1.1522301683606377
v -1 0.1290776186760123 -0.20723151219433733
v -1 0.5822315121943373 0.004077618676012307
v -1 -0.5048497739350366 1.1522301683606377
v -1 -0.05169588041671158 1.3635392992309874
vt 0.5 0.5625
vt 0.46875 0.5625
vt 0.46875 0.46875
vt 0.5 0.46875
vt 0.53125 0.5625
vt 0.5 0.5625
vt 0.5 0.46875
vt 0.53125 0.46875
vt 0.5625 0.5625
vt 0.53125 0.5625
vt 0.53125 0.46875
vt 0.5625 0.46875
vt 0.46875 0.5625
vt 0.4375 0.5625
vt 0.4375 0.46875
vt 0.46875 0.46875
vt 0.46875 0.5625
vt 0.5 0.5625
vt 0.5 0.59375
vt 0.46875 0.59375
vt 0.5 0.59375
vt 0.53125 0.59375
vt 0.53125 0.5625
vt 0.5 0.5625
vn 0 -0.90630778703665 -0.4226182617406993
vn 1 0 0
vn 0 0.90630778703665 0.4226182617406993
vn -1 0 0
vn 0 0.4226182617406993 -0.90630778703665
vn 0 -0.4226182617406993 0.90630778703665
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 308/916/229 311/915/229 309/914/229 306/913/229
f 307/920/230 308/919/230 306/918/230 305/917/230
f 312/924/231 307/923/231 305/922/231 310/921/231
f 311/928/232 312/927/232 310/926/232 309/925/232
f 310/932/233 305/931/233 306/930/233 309/929/233
f 311/936/234 308/935/234 307/934/234 312/933/234
o frontlegtip
v -0.5625 -0.10149613187923717 1.346273081444131
v -0.5625 -0.3666611748241926 1.0811080384991758
v -0.5625 -1.1621563036590583 2.4069332532239525
v -0.5625 -1.4273213466040136 2.141768210278997
v -0.9375 -0.3666611748241926 1.0811080384991758
v -0.9375 -0.10149613187923717 1.346273081444131
v -0.9375 -1.4273213466040136 2.141768210278997
v -0.9375 -1.1621563036590583 2.4069332532239525
vt 0.9296875 0.4375
vt 0.90625 0.4375
vt 0.90625 0.34375
vt 0.9296875 0.34375
vt 0.953125 0.4375
vt 0.9296875 0.4375
vt 0.9296875 0.34375
vt 0.953125 0.34375
vt 0.9765625 0.4375
vt 0.953125 0.4375
vt 0.953125 0.34375
vt 0.9765625 0.34375
vt 0.90625 0.4375
vt 0.8828125 0.4375
vt 0.8828125 0.34375
vt 0.90625 0.34375
vt 0.90625 0.4375
vt 0.9296875 0.4375
vt 0.9296875 0.4609375
vt 0.90625 0.4609375
vt 0.9296875 0.4609375
vt 0.953125 0.4609375
vt 0.953125 0.4375
vt 0.9296875 0.4375
vn 0 -0.7071067811865477 -0.7071067811865474
vn 1 0 0
vn 0 0.7071067811865477 0.7071067811865474
vn -1 0 0
vn 0 0.7071067811865474 -0.7071067811865477
vn 0 -0.7071067811865474 0.7071067811865477
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 316/940/235 319/939/235 317/938/235 314/937/235
f 315/944/236 316/943/236 314/942/236 313/941/236
f 320/948/237 315/947/237 313/946/237 318/945/237
f 319/952/238 320/951/238 318/950/238 317/949/238
f 318/956/239 313/955/239 314/954/239 317/953/239
f 319/960/240 316/959/240 315/958/240 320/957/240
o frontfoot
v -0.5 -1.0005446513073766 2.2301565579273155
v -0.5 -2.0005446513073766 2.2301565579273155
v -0.5 -1.0005446513073766 2.4801565579273155
v -0.5 -2.0005446513073766 2.4801565579273155
v -1 -2.0005446513073766 2.2301565579273155
v -1 -1.0005446513073766 2.2301565579273155
v -1 -2.0005446513073766 2.4801565579273155
v -1 -1.0005446513073766 2.4801565579273155
vt 0.65625 0.53125
vt 0.625 0.53125
vt 0.625 0.515625
vt 0.65625 0.515625
vt 0.71875 0.53125
vt 0.65625 0.53125
vt 0.65625 0.515625
vt 0.71875 0.515625
vt 0.75 0.53125
vt 0.71875 0.53125
vt 0.71875 0.515625
vt 0.75 0.515625
vt 0.625 0.53125
vt 0.5625 0.53125
vt 0.5625 0.515625
vt 0.625 0.515625
vt 0.625 0.53125
vt 0.65625 0.53125
vt 0.65625 0.59375
vt 0.625 0.59375
vt 0.65625 0.59375
vt 0.6875 0.59375
vt 0.6875 0.53125
vt 0.65625 0.53125
vn 0 -1 2.7755575615628914e-16
vn 1 0 0
vn 0 1 -2.7755575615628914e-16
vn -1 0 0
vn 0 -2.7755575615628914e-16 -1
vn 0 2.7755575615628914e-16 1
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 324/964/241 327/963/241 325/962/241 322/961/241
f 323/968/242 324/967/242 322/966/242 321/965/242
f 328/972/243 323/971/243 321/970/243 326/969/243
f 327/976/244 328/975/244 326/974/244 325/973/244
f 326/980/245 321/979/245 322/978/245 325/977/245
f 327/984/246 324/983/246 323/982/246 328/981/246
o tail
v 0.3125 1.1875 4.125
v 0.3125 1.1875 3.5
v 0.3125 0.5625 4.125
v 0.3125 0.5625 3.5
v -0.3125 1.1875 3.5
v -0.3125 1.1875 4.125
v -0.3125 0.5625 3.5
v -0.3125 0.5625 4.125
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 332/988/247 335/987/247 333/986/247 330/985/247
f 331/992/248 332/991/248 330/990/248 329/989/248
f 336/996/249 331/995/249 329/994/249 334/993/249
f 335/1000/250 336/999/250 334/998/250 333/997/250
f 334/1004/251 329/1003/251 330/1002/251 333/1001/251
f 335/1008/252 332/1007/252 331/1006/252 336/1005/252
o tail
v 0.0625 1.4375 4
v 0.0625 1.4375 3.625
v 0.0625 1.1875 4
v 0.0625 1.1875 3.625
v -0.0625 1.4375 3.625
v -0.0625 1.4375 4
v -0.0625 1.1875 3.625
v -0.0625 1.1875 4
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 340/1012/253 343/1011/253 341/1010/253 338/1009/253
f 339/1016/254 340/1015/254 338/1014/254 337/1013/254
f 344/1020/255 339/1019/255 337/1018/255 342/1017/255
f 343/1024/256 344/1023/256 342/1022/256 341/1021/256
f 342/1028/257 337/1027/257 338/1026/257 341/1025/257
f 343/1032/258 340/1031/258 339/1030/258 344/1029/258
o tail
v 0.3125 1.1983601587596744 4.744450932461093
v 0.3125 1.1874524047363721 4.119546122988348
v 0.3125 0.5734553492869299 4.755358686484396
v 0.3125 0.5625475952636276 4.130453877011651
v -0.3125 1.1874524047363721 4.119546122988348
v -0.3125 1.1983601587596744 4.744450932461093
v -0.3125 0.5625475952636276 4.130453877011651
v -0.3125 0.5734553492869299 4.755358686484396
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.01745240643728351 -0.9998476951563913
vn 1 0 0
vn 0 0.01745240643728351 0.9998476951563913
vn -1 0 0
vn 0 0.9998476951563913 -0.01745240643728351
vn 0 -0.9998476951563913 0.01745240643728351
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 348/1036/259 351/1035/259 349/1034/259 346/1033/259
f 347/1040/260 348/1039/260 346/1038/260 345/1037/260
f 352/1044/261 347/1043/261 345/1042/261 350/1041/261
f 351/1048/262 352/1047/262 350/1046/262 349/1045/262
f 350/1052/263 345/1051/263 346/1050/263 349/1049/263
f 351/1056/264 348/1055/264 347/1054/264 352/1053/264
o tail
v 0.0625 1.4461405317441118 4.615106868957223
v 0.0625 1.4395958793301304 4.240163983273576
v 0.0625 1.196178607955014 4.619469970566544
v 0.0625 1.1896339555410327 4.244527084882897
v -0.0625 1.4395958793301304 4.240163983273576
v -0.0625 1.4461405317441118 4.615106868957223
v -0.0625 1.1896339555410327 4.244527084882897
v -0.0625 1.196178607955014 4.619469970566544
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.01745240643728351 -0.9998476951563913
vn 1 0 0
vn 0 0.01745240643728351 0.9998476951563913
vn -1 0 0
vn 0 0.9998476951563913 -0.01745240643728351
vn 0 -0.9998476951563913 0.01745240643728351
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 356/1060/265 359/1059/265 357/1058/265 354/1057/265
f 355/1064/266 356/1063/266 354/1062/266 353/1061/266
f 360/1068/267 355/1067/267 353/1066/267 358/1065/267
f 359/1072/268 360/1071/268 358/1070/268 357/1069/268
f 358/1076/269 353/1075/269 354/1074/269 357/1073/269
f 359/1080/270 356/1079/270 355/1078/270 360/1077/270
o tail
v 0.3125 1.2200295729058328 5.363617983640148
v 0.3125 1.1982173874667696 4.738998716753213
v 0.3125 0.595410306018898 5.385430169079211
v 0.3125 0.5735981205798348 4.760810902192276
v -0.3125 1.1982173874667696 4.738998716753213
v -0.3125 1.2200295729058328 5.363617983640148
v -0.3125 0.5735981205798348 4.760810902192276
v -0.3125 0.595410306018898 5.385430169079211
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.03489949670250097 -0.9993908270190958
vn 1 0 0
vn 0 0.03489949670250097 0.9993908270190958
vn -1 0 0
vn 0 0.9993908270190958 -0.03489949670250097
vn 0 -0.9993908270190958 0.03489949670250097
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 364/1084/271 367/1083/271 365/1082/271 362/1081/271
f 363/1088/272 364/1087/272 362/1086/272 361/1085/272
f 368/1092/273 363/1091/273 361/1090/273 366/1089/273
f 367/1096/274 368/1095/274 366/1094/274 365/1093/274
f 366/1100/275 361/1099/275 362/1098/275 365/1097/275
f 367/1104/276 364/1103/276 363/1102/276 368/1101/276
o tail
v 0.0625 1.465514842572794 5.229969256087136
v 0.0625 1.4524275313093562 4.855197695954975
v 0.0625 1.21566713581802 5.238694130262761
v 0.0625 1.2025798245545822 4.8639225701306
v -0.0625 1.4524275313093562 4.855197695954975
v -0.0625 1.465514842572794 5.229969256087136
v -0.0625 1.2025798245545822 4.8639225701306
v -0.0625 1.21566713581802 5.238694130262761
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.03489949670250097 -0.9993908270190958
vn 1 0 0
vn 0 0.03489949670250097 0.9993908270190958
vn -1 0 0
vn 0 0.9993908270190958 -0.03489949670250097
vn 0 -0.9993908270190958 0.03489949670250097
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 372/1108/277 375/1107/277 373/1106/277 370/1105/277
f 371/1112/278 372/1111/278 370/1110/278 369/1109/278
f 376/1116/279 371/1115/279 369/1114/279 374/1113/279
f 375/1120/280 376/1119/280 374/1118/280 373/1117/280
f 374/1124/281 369/1123/281 370/1122/281 373/1121/281
f 375/1128/282 372/1127/282 371/1126/282 376/1125/282
o tail
v 0.3125 1.2525016417250097 5.982312549255369
v 0.3125 1.2197916690731698 5.35816909003376
v 0.3125 0.628358182503401 6.015022521907208
v 0.3125 0.5956482098515612 5.3908790626856
v -0.3125 1.2197916690731698 5.35816909003376
v -0.3125 1.2525016417250097 5.982312549255369
v -0.3125 0.5956482098515612 5.3908790626856
v -0.3125 0.628358182503401 6.015022521907208
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.05233595624294383 -0.9986295347545739
vn 1 0 0
vn 0 0.05233595624294383 0.9986295347545739
vn -1 0 0
vn 0 0.9986295347545739 -0.05233595624294383
vn 0 -0.9986295347545739 0.05233595624294383
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 380/1132/283 383/1131/283 381/1130/283 378/1129/283
f 379/1136/284 380/1135/284 378/1134/284 377/1133/284
f 384/1140/285 379/1139/285 377/1138/285 382/1137/285
f 383/1144/286 384/1143/286 382/1142/286 381/1141/286
f 382/1148/287 377/1147/287 378/1146/287 381/1145/287
f 383/1152/288 380/1151/288 379/1150/288 384/1149/288
o tail
v 0.0625 1.4956170308832855 5.844399868350311
v 0.0625 1.4759910472921813 5.469913792817346
v 0.0625 1.245959647194642 5.857483857411047
v 0.0625 1.2263336636035378 5.482997781878082
v -0.0625 1.4759910472921813 5.469913792817346
v -0.0625 1.4956170308832855 5.844399868350311
v -0.0625 1.2263336636035378 5.482997781878082
v -0.0625 1.245959647194642 5.857483857411047
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.05233595624294383 -0.9986295347545739
vn 1 0 0
vn 0 0.05233595624294383 0.9986295347545739
vn -1 0 0
vn 0 0.9986295347545739 -0.05233595624294383
vn 0 -0.9986295347545739 0.05233595624294383
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 388/1156/289 391/1155/289 389/1154/289 386/1153/289
f 387/1160/290 388/1159/290 386/1158/290 385/1157/290
f 392/1164/291 387/1163/291 385/1162/291 390/1161/291
f 391/1168/292 392/1167/292 390/1166/292 389/1165/292
f 390/1172/293 385/1171/293 386/1170/293 389/1169/293
f 391/1176/294 388/1175/294 387/1174/294 392/1173/294
o tail
v 0.3125 1.3062130944851622 6.594053052279986
v 0.3125 1.2517407552678759 5.971431365972645
v 0.3125 0.6835914081778212 6.648525391497273
v 0.3125 0.6291190689605348 6.025903705189932
v -0.3125 1.2517407552678759 5.971431365972645
v -0.3125 1.3062130944851622 6.594053052279986
v -0.3125 0.6291190689605348 6.025903705189932
v -0.3125 0.6835914081778212 6.648525391497273
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.08715574274765817 -0.9961946980917457
vn 1 0 0
vn 0 0.08715574274765817 0.9961946980917457
vn -1 0 0
vn 0 0.9961946980917457 -0.08715574274765817
vn 0 -0.9961946980917457 0.08715574274765817
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 396/1180/295 399/1179/295 397/1178/295 394/1177/295
f 395/1184/296 396/1183/296 394/1182/296 393/1181/296
f 400/1188/297 395/1187/297 393/1186/297 398/1185/297
f 399/1192/298 400/1191/298 398/1190/298 397/1189/298
f 398/1196/299 393/1195/299 394/1194/299 397/1193/299
f 399/1200/300 396/1199/300 395/1198/300 400/1197/300
o tail
v 0.0625 1.544367301164641 6.447739779331603
v 0.0625 1.5116838976342697 6.074166767547199
v 0.0625 1.295318626641705 6.469528715018518
v 0.0625 1.2626352231113331 6.095955703234114
v -0.0625 1.5116838976342697 6.074166767547199
v -0.0625 1.544367301164641 6.447739779331603
v -0.0625 1.2626352231113331 6.095955703234114
v -0.0625 1.295318626641705 6.469528715018518
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.08715574274765817 -0.9961946980917457
vn 1 0 0
vn 0 0.08715574274765817 0.9961946980917457
vn -1 0 0
vn 0 0.9961946980917457 -0.08715574274765817
vn 0 -0.9961946980917457 0.08715574274765817
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 404/1204/301 407/1203/301 405/1202/301 402/1201/301
f 403/1208/302 404/1207/302 402/1206/302 401/1205/302
f 408/1212/303 403/1211/303 401/1210/303 406/1209/303
f 407/1216/304 408/1215/304 406/1214/304 405/1213/304
f 406/1220/305 401/1219/305 402/1218/305 405/1217/305
f 407/1224/306 404/1223/306 403/1222/306 408/1221/306
o tail
v 0.3125 1.3913442109132734 7.196715170802091
v 0.3125 1.304361022813232 6.577797627838609
v 0.3125 0.772426667949792 7.283698358902132
v 0.3125 0.6854434798497508 6.66478081593865
v -0.3125 1.304361022813232 6.577797627838609
v -0.3125 1.3913442109132734 7.196715170802091
v -0.3125 0.6854434798497508 6.66478081593865
v -0.3125 0.772426667949792 7.283698358902132
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.13917310096006544 -0.9902680687415704
vn 1 0 0
vn 0 0.13917310096006544 0.9902680687415704
vn -1 0 0
vn 0 0.9902680687415704 -0.13917310096006544
vn 0 -0.9902680687415704 0.13917310096006544
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 412/1228/307 415/1227/307 413/1226/307 410/1225/307
f 411/1232/308 412/1231/308 410/1230/308 409/1229/308
f 416/1236/309 411/1235/309 409/1234/309 414/1233/309
f 415/1240/310 416/1239/310 414/1238/310 413/1237/310
f 414/1244/311 409/1243/311 410/1242/311 413/1241/311
f 415/1248/312 412/1247/312 411/1246/312 416/1245/312
o tail
v 0.0625 1.6215145904786574 7.038138386969378
v 0.0625 1.5693246776186331 6.666787861191289
v 0.0625 1.373947573293265 7.0729316622093945
v 0.0625 1.3217576604332402 6.701581136431305
v -0.0625 1.5693246776186331 6.666787861191289
v -0.0625 1.6215145904786574 7.038138386969378
v -0.0625 1.3217576604332402 6.701581136431305
v -0.0625 1.373947573293265 7.0729316622093945
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.13917310096006544 -0.9902680687415704
vn 1 0 0
vn 0 0.13917310096006544 0.9902680687415704
vn -1 0 0
vn 0 0.9902680687415704 -0.13917310096006544
vn 0 -0.9902680687415704 0.13917310096006544
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 420/1252/313 423/1251/313 421/1250/313 418/1249/313
f 419/1256/314 420/1255/314 418/1254/314 417/1253/314
f 424/1260/315 419/1259/315 417/1258/315 422/1257/315
f 423/1264/316 424/1263/316 422/1262/316 421/1261/316
f 422/1268/317 417/1267/317 418/1266/317 421/1265/317
f 423/1272/318 420/1271/318 419/1270/318 424/1269/318
o tail
v 0.3125 1.507899556369268 7.79409594345173
v 0.3125 1.3886439342589278 7.180578953796941
v 0.3125 0.894382566714478 7.9133515655620705
v 0.3125 0.7751269446041376 7.299834575907282
v -0.3125 1.3886439342589278 7.180578953796941
v -0.3125 1.507899556369268 7.79409594345173
v -0.3125 0.7751269446041376 7.299834575907282
v -0.3125 0.894382566714478 7.9133515655620705
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.1908089953765448 -0.981627183447664
vn 1 0 0
vn 0 0.1908089953765448 0.981627183447664
vn -1 0 0
vn 0 0.981627183447664 -0.1908089953765448
vn 0 -0.981627183447664 0.1908089953765448
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 428/1276/319 431/1275/319 429/1274/319 426/1273/319
f 427/1280/320 428/1279/320 426/1278/320 425/1277/320
f 432/1284/321 427/1283/321 425/1282/321 430/1281/321
f 431/1288/322 432/1287/322 430/1286/322 429/1285/322
f 430/1292/323 425/1291/323 426/1290/323 429/1289/323
f 431/1296/324 428/1295/324 427/1294/324 432/1293/324
o tail
v 0.0625 1.7294552278091162 7.623690296676635
v 0.0625 1.6579018545429118 7.255580102883762
v 0.0625 1.4840484319472 7.6713925455207725
v 0.0625 1.4124950586809957 7.303282351727899
v -0.0625 1.6579018545429118 7.255580102883762
v -0.0625 1.7294552278091162 7.623690296676635
v -0.0625 1.4124950586809957 7.303282351727899
v -0.0625 1.4840484319472 7.6713925455207725
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.1908089953765448 -0.981627183447664
vn 1 0 0
vn 0 0.1908089953765448 0.981627183447664
vn -1 0 0
vn 0 0.981627183447664 -0.1908089953765448
vn 0 -0.981627183447664 0.1908089953765448
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 436/1300/325 439/1299/325 437/1298/325 434/1297/325
f 435/1304/326 436/1303/326 434/1302/326 433/1301/326
f 440/1308/327 435/1307/327 433/1306/327 438/1305/327
f 439/1312/328 440/1311/328 438/1310/328 437/1309/328
f 438/1316/329 433/1315/329 434/1314/329 437/1313/329
f 439/1320/330 436/1319/330 435/1318/330 440/1317/330
o tail
v 0.3125 1.6367569935322868 8.40009360158498
v 0.3125 1.506812186771187 7.788751351126351
v 0.3125 1.025414743073658 8.53003840834608
v 0.3125 0.8954699363125587 7.918696157887451
v -0.3125 1.506812186771187 7.788751351126351
v -0.3125 1.6367569935322868 8.40009360158498
v -0.3125 0.8954699363125587 7.918696157887451
v -0.3125 1.025414743073658 8.53003840834608
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.20791169081775934 -0.9781476007338057
vn 1 0 0
vn 0 0.20791169081775934 0.9781476007338057
vn -1 0 0
vn 0 0.9781476007338057 -0.20791169081775934
vn 0 -0.9781476007338057 0.20791169081775934
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 444/1324/331 447/1323/331 445/1322/331 442/1321/331
f 443/1328/332 444/1327/332 442/1326/332 441/1325/332
f 448/1332/333 443/1331/333 441/1330/333 446/1329/333
f 447/1336/334 448/1335/334 446/1334/334 445/1333/334
f 446/1340/335 441/1339/335 442/1338/335 445/1337/335
f 447/1344/336 444/1343/336 443/1342/336 448/1341/336
o tail
v 0.0625 1.8553049323635182 8.225847228788815
v 0.0625 1.7773380483068584 7.8590418785136364
v 0.0625 1.6107680321800668 8.277825151493253
v 0.0625 1.532801148123407 7.911019801218078
v -0.0625 1.7773380483068584 7.8590418785136364
v -0.0625 1.8553049323635182 8.225847228788815
v -0.0625 1.532801148123407 7.911019801218078
v -0.0625 1.6107680321800668 8.277825151493253
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.20791169081775934 -0.9781476007338057
vn 1 0 0
vn 0 0.20791169081775934 0.9781476007338057
vn -1 0 0
vn 0 0.9781476007338057 -0.20791169081775934
vn 0 -0.9781476007338057 0.20791169081775934
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 452/1348/337 455/1347/337 453/1346/337 450/1345/337
f 451/1352/338 452/1351/338 450/1350/338 449/1349/338
f 456/1356/339 451/1355/339 449/1354/339 454/1353/339
f 455/1360/340 456/1359/340 454/1358/340 453/1357/340
f 454/1364/341 449/1363/341 450/1362/341 453/1361/341
f 455/1368/342 452/1367/342 451/1366/342 456/1365/342
o tail
v 0.3125 1.7570999852407079 9.018955183565149
v 0.3125 1.6378443631303679 8.405438193910358
v 0.3125 1.143582995585918 9.13821080567549
v 0.3125 1.0243273734755776 8.524693816020699
v -0.3125 1.6378443631303679 8.405438193910358
v -0.3125 1.7570999852407079 9.018955183565149
v -0.3125 1.0243273734755776 8.524693816020699
v -0.3125 1.143582995585918 9.13821080567549
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.1908089953765448 -0.981627183447664
vn 1 0 0
vn 0 0.1908089953765448 0.981627183447664
vn -1 0 0
vn 0 0.981627183447664 -0.1908089953765448
vn 0 -0.981627183447664 0.1908089953765448
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 460/1372/343 463/1371/343 461/1370/343 458/1369/343
f 459/1376/344 460/1375/344 458/1374/344 457/1373/344
f 464/1380/345 459/1379/345 457/1378/345 462/1377/345
f 463/1384/346 464/1383/346 462/1382/346 461/1381/346
f 462/1388/347 457/1387/347 458/1386/347 461/1385/347
f 463/1392/348 460/1391/348 459/1390/348 464/1389/348
o tail
v 0.0625 1.978655656680556 8.848549536790054
v 0.0625 1.9071022834143516 8.480439342997181
v 0.0625 1.73324886081864 8.89625178563419
v 0.0625 1.6616954875524357 8.528141591841317
v -0.0625 1.9071022834143516 8.480439342997181
v -0.0625 1.978655656680556 8.848549536790054
v -0.0625 1.6616954875524357 8.528141591841317
v -0.0625 1.73324886081864 8.89625178563419
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.1908089953765448 -0.981627183447664
vn 1 0 0
vn 0 0.1908089953765448 0.981627183447664
vn -1 0 0
vn 0 0.981627183447664 -0.1908089953765448
vn 0 -0.981627183447664 0.1908089953765448
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 468/1396/349 471/1395/349 469/1394/349 466/1393/349
f 467/1400/350 468/1399/350 466/1398/350 465/1397/350
f 472/1404/351 467/1403/351 465/1402/351 470/1401/351
f 471/1408/352 472/1407/352 470/1406/352 469/1405/352
f 470/1412/353 465/1411/353 466/1410/353 469/1409/353
f 471/1416/354 468/1415/354 467/1414/354 472/1413/354
o tail
v 0.3125 1.8567656374994383 9.64700243716721
v 0.3125 1.7589940968492939 9.029697224295248
v 0.3125 1.239460424627477 9.744773977817353
v 0.3125 1.1416888839773327 9.127468764945391
v -0.3125 1.7589940968492939 9.029697224295248
v -0.3125 1.8567656374994383 9.64700243716721
v -0.3125 1.1416888839773327 9.127468764945391
v -0.3125 1.239460424627477 9.744773977817353
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.15643446504023087 -0.9876883405951378
vn 1 0 0
vn 0 0.15643446504023087 0.9876883405951378
vn -1 0 0
vn 0 0.9876883405951378 -0.15643446504023087
vn 0 -0.9876883405951378 0.15643446504023087
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 476/1420/355 479/1419/355 477/1418/355 474/1417/355
f 475/1424/356 476/1423/356 474/1422/356 473/1421/356
f 480/1428/357 475/1427/357 473/1426/357 478/1425/357
f 479/1432/358 480/1431/358 478/1430/358 477/1429/358
f 478/1436/359 473/1435/359 474/1434/359 477/1433/359
f 479/1440/360 476/1439/360 475/1438/360 480/1437/360
o tail
v 0.0625 2.084133414518194 9.484432778332758
v 0.0625 2.025470490128107 9.114049650609582
v 0.0625 1.8372113293694095 9.523541394592817
v 0.0625 1.7785484049793228 9.15315826686964
v -0.0625 2.025470490128107 9.114049650609582
v -0.0625 2.084133414518194 9.484432778332758
v -0.0625 1.7785484049793228 9.15315826686964
v -0.0625 1.8372113293694095 9.523541394592817
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.15643446504023087 -0.9876883405951378
vn 1 0 0
vn 0 0.15643446504023087 0.9876883405951378
vn -1 0 0
vn 0 0.9876883405951378 -0.15643446504023087
vn 0 -0.9876883405951378 0.15643446504023087
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 484/1444/361 487/1443/361 485/1442/361 482/1441/361
f 483/1448/362 484/1447/362 482/1446/362 481/1445/362
f 488/1452/363 483/1451/363 481/1450/363 486/1449/363
f 487/1456/364 488/1455/364 486/1454/364 485/1453/364
f 486/1460/365 481/1459/365 482/1458/365 485/1457/365
f 487/1464/366 484/1463/366 483/1462/366 488/1461/366
o tail
v 0.3125 1.924231412908326 10.284799247326308
v 0.3125 1.858901123366043 9.663223062721139
v 0.3125 1.3026552283031554 10.350129536868593
v 0.3125 1.237324938760872 9.728553352263424
v -0.3125 1.858901123366043 9.663223062721139
v -0.3125 1.924231412908326 10.284799247326308
v -0.3125 1.237324938760872 9.728553352263424
v -0.3125 1.3026552283031554 10.350129536868593
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.10452846326765347 -0.9945218953682733
vn 1 0 0
vn 0 0.10452846326765347 0.9945218953682733
vn -1 0 0
vn 0 0.9945218953682733 -0.10452846326765347
vn 0 -0.9945218953682733 0.10452846326765347
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 492/1468/367 495/1467/367 493/1466/367 490/1465/367
f 491/1472/368 492/1471/368 490/1470/368 489/1469/368
f 496/1476/369 491/1475/369 489/1474/369 494/1473/369
f 495/1480/370 496/1479/370 494/1478/370 493/1477/370
f 494/1484/371 489/1483/371 490/1482/371 493/1481/371
f 495/1488/372 492/1487/372 491/1486/372 496/1485/372
o tail
v 0.0625 2.159795828841938 10.134351894588363
v 0.0625 2.120597655116568 9.76140618382526
v 0.0625 1.91116535499987 10.160484010405275
v 0.0625 1.8719671812745 9.787538299642172
v -0.0625 2.120597655116568 9.76140618382526
v -0.0625 2.159795828841938 10.134351894588363
v -0.0625 1.8719671812745 9.787538299642172
v -0.0625 1.91116535499987 10.160484010405275
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.10452846326765347 -0.9945218953682733
vn 1 0 0
vn 0 0.10452846326765347 0.9945218953682733
vn -1 0 0
vn 0 0.9945218953682733 -0.10452846326765347
vn 0 -0.9945218953682733 0.10452846326765347
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 500/1492/373 503/1491/373 501/1490/373 498/1489/373
f 499/1496/374 500/1495/374 498/1494/374 497/1493/374
f 504/1500/375 499/1499/375 497/1498/375 502/1497/375
f 503/1504/376 504/1503/376 502/1502/376 501/1501/376
f 502/1508/377 497/1507/377 498/1506/377 501/1505/377
f 503/1512/378 500/1511/378 499/1510/378 504/1509/378
o tail
v 0.3125 1.958225022868385 10.92525286499314
v 0.3125 1.9255150502165446 10.301109405771532
v 0.3125 1.3340815636467762 10.957962837644981
v 0.3125 1.3013715909949364 10.333819378423373
v -0.3125 1.9255150502165446 10.301109405771532
v -0.3125 1.958225022868385 10.92525286499314
v -0.3125 1.3013715909949364 10.333819378423373
v -0.3125 1.3340815636467762 10.957962837644981
vt 0.7890625 0.5546875
vt 0.828125 0.5546875
vt 0.828125 0.515625
vt 0.7890625 0.515625
vt 0.75 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.515625
vt 0.75 0.515625
vt 0.8671875 0.5546875
vt 0.90625 0.5546875
vt 0.90625 0.515625
vt 0.8671875 0.515625
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vt 0.8671875 0.515625
vt 0.828125 0.515625
vt 0.828125 0.5546875
vt 0.7890625 0.5546875
vt 0.7890625 0.59375
vt 0.828125 0.59375
vt 0.8671875 0.59375
vt 0.828125 0.59375
vt 0.828125 0.5546875
vt 0.8671875 0.5546875
vn 0 -0.05233595624294385 -0.998629534754574
vn 1 0 0
vn 0 0.05233595624294385 0.998629534754574
vn -1 0 0
vn 0 0.998629534754574 -0.05233595624294385
vn 0 -0.998629534754574 0.05233595624294385
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 508/1516/379 511/1515/379 509/1514/379 506/1513/379
f 507/1520/380 508/1519/380 506/1518/380 505/1517/380
f 512/1524/381 507/1523/381 505/1522/381 510/1521/381
f 511/1528/382 512/1527/382 510/1526/382 509/1525/382
f 510/1532/383 505/1531/383 506/1530/383 509/1529/383
f 511/1536/384 508/1535/384 507/1534/384 512/1533/384
o tail
v 0.0625 2.20134041202666 10.787340184088084
v 0.0625 2.1817144284355567 10.412854108555118
v 0.0625 1.9516830283380169 10.80042417314882
v 0.0625 1.9320570447469132 10.425938097615854
v -0.0625 2.1817144284355567 10.412854108555118
v -0.0625 2.20134041202666 10.787340184088084
v -0.0625 1.9320570447469132 10.425938097615854
v -0.0625 1.9516830283380169 10.80042417314882
vt 0.2109375 0.9765625
vt 0.21875 0.9765625
vt 0.21875 0.9609375
vt 0.2109375 0.9609375
vt 0.1875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 0.9609375
vt 0.1875 0.9609375
vt 0.2421875 0.9765625
vt 0.25 0.9765625
vt 0.25 0.9609375
vt 0.2421875 0.9609375
vt 0.21875 0.9765625
vt 0.2421875 0.9765625
vt 0.2421875 0.9609375
vt 0.21875 0.9609375
vt 0.21875 0.9765625
vt 0.2109375 0.9765625
vt 0.2109375 1
vt 0.21875 1
vt 0.2265625 1
vt 0.21875 1
vt 0.21875 0.9765625
vt 0.2265625 0.9765625
vn 0 -0.05233595624294385 -0.998629534754574
vn 1 0 0
vn 0 0.05233595624294385 0.998629534754574
vn -1 0 0
vn 0 0.998629534754574 -0.05233595624294385
vn 0 -0.998629534754574 0.05233595624294385
usemtl m_e48612ca-0475-df4f-6d9d-44f43691817f
f 516/1540/385 519/1539/385 517/1538/385 514/1537/385
f 515/1544/386 516/1543/386 514/1542/386 513/1541/386
f 520/1548/387 515/1547/387 513/1546/387 518/1545/387
f 519/1552/388 520/1551/388 518/1550/388 517/1549/388
f 518/1556/389 513/1555/389 514/1554/389 517/1553/389
f 519/1560/390 516/1559/390 515/1558/390 520/1557/390