import Screen from './Screen'
import { useIsWidgetActive } from './utilsApp'

const __FullScreenWidget_COMPONENT = ({ name, title, children }) => {
  const isWidgetActive = useIsWidgetActive(name)

  if (!isWidgetActive) return null

  return <Screen backdrop title={title}>{children}</Screen>
}
;__FullScreenWidget_COMPONENT.displayName = 'FullScreenWidget';export default __FullScreenWidget_COMPONENT;