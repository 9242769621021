# Made in Blockbench 4.9.4
mtllib materials.mtl

o Body
v 0.3125 1.3125 0.6875
v 0.3125 1.3125 -0.6875
v 0.3125 0.6875 0.6875
v 0.3125 0.6875 -0.6875
v -0.3125 1.3125 -0.6875
v -0.3125 1.3125 0.6875
v -0.3125 0.6875 -0.6875
v -0.3125 0.6875 0.6875
vt 0.34375 0.15625
vt 0.5 0.15625
vt 0.5 0
vt 0.34375 0
vt 0 0.15625
vt 0.34375 0.15625
vt 0.34375 0
vt 0 0
vt 0.84375 0.15625
vt 1 0.15625
vt 1 0
vt 0.84375 0
vt 0.5 0.15625
vt 0.84375 0.15625
vt 0.84375 0
vt 0.5 0
vt 0.5 0.15625
vt 0.34375 0.15625
vt 0.34375 0.5
vt 0.5 0.5
vt 0.65625 0.5
vt 0.5 0.5
vt 0.5 0.15625
vt 0.65625 0.15625
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 4/4/1 7/3/1 5/2/1 2/1/1
f 3/8/2 4/7/2 2/6/2 1/5/2
f 8/12/3 3/11/3 1/10/3 6/9/3
f 7/16/4 8/15/4 6/14/4 5/13/4
f 6/20/5 1/19/5 2/18/5 5/17/5
f 7/24/6 4/23/6 3/22/6 8/21/6
o TailA
v 0.09375 1.3125 0.7957531754730547
v 0.09375 1.1875 0.5792468245269451
v 0.09375 0.5547277716886161 1.2332531754730547
v 0.09375 0.4297277716886161 1.0167468245269449
v -0.09375 1.1875 0.5792468245269451
v -0.09375 1.3125 0.7957531754730547
v -0.09375 0.4297277716886161 1.0167468245269449
v -0.09375 0.5547277716886161 1.2332531754730547
vt 0.71875 0.375
vt 0.765625 0.375
vt 0.765625 0.15625
vt 0.71875 0.15625
vt 0.65625 0.375
vt 0.71875 0.375
vt 0.71875 0.15625
vt 0.65625 0.15625
vt 0.828125 0.375
vt 0.875 0.375
vt 0.875 0.15625
vt 0.828125 0.15625
vt 0.765625 0.375
vt 0.828125 0.375
vt 0.828125 0.15625
vt 0.765625 0.15625
vt 0.765625 0.375
vt 0.71875 0.375
vt 0.71875 0.4375
vt 0.765625 0.4375
vt 0.8125 0.4375
vt 0.765625 0.4375
vt 0.765625 0.375
vt 0.8125 0.375
vn 0 -0.49999999999999994 -0.8660254037844387
vn 1 0 0
vn 0 0.49999999999999994 0.8660254037844387
vn -1 0 0
vn 0 0.8660254037844387 -0.49999999999999994
vn 0 -0.8660254037844387 0.49999999999999994
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 12/28/7 15/27/7 13/26/7 10/25/7
f 11/32/8 12/31/8 10/30/8 9/29/8
f 16/36/9 11/35/9 9/34/9 14/33/9
f 15/40/10 16/39/10 14/38/10 13/37/10
f 14/44/11 9/43/11 10/42/11 13/41/11
f 15/48/12 12/47/12 11/46/12 16/45/12
o Leg1A
v -0.0625 0.6875 0.6875
v -0.0625 0.6875 0.4375
v -0.0625 0 0.6875
v -0.0625 0 0.4375
v -0.3125 0.6875 0.4375
v -0.3125 0.6875 0.6875
v -0.3125 0 0.4375
v -0.3125 0 0.6875
vt 0.875 0.609375
vt 0.8125 0.609375
vt 0.8125 0.4375
vt 0.875 0.4375
vt 0.9375 0.609375
vt 0.875 0.609375
vt 0.875 0.4375
vt 0.9375 0.4375
vt 1 0.609375
vt 0.9375 0.609375
vt 0.9375 0.4375
vt 1 0.4375
vt 0.8125 0.609375
vt 0.75 0.609375
vt 0.75 0.4375
vt 0.8125 0.4375
vt 0.8125 0.609375
vt 0.875 0.609375
vt 0.875 0.671875
vt 0.8125 0.671875
vt 0.875 0.671875
vt 0.9375 0.671875
vt 0.9375 0.609375
vt 0.875 0.609375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 20/52/13 23/51/13 21/50/13 18/49/13
f 19/56/14 20/55/14 18/54/14 17/53/14
f 24/60/15 19/59/15 17/58/15 22/57/15
f 23/64/16 24/63/16 22/62/16 21/61/16
f 22/68/17 17/67/17 18/66/17 21/65/17
f 23/72/18 20/71/18 19/70/18 24/69/18
o Leg2A
v 0.3125 0.6875 0.6875
v 0.3125 0.6875 0.4375
v 0.3125 0 0.6875
v 0.3125 0 0.4375
v 0.0625 0.6875 0.4375
v 0.0625 0.6875 0.6875
v 0.0625 0 0.4375
v 0.0625 0 0.6875
vt 0.8125 0.609375
vt 0.875 0.609375
vt 0.875 0.4375
vt 0.8125 0.4375
vt 0.75 0.609375
vt 0.8125 0.609375
vt 0.8125 0.4375
vt 0.75 0.4375
vt 0.9375 0.609375
vt 1 0.609375
vt 1 0.4375
vt 0.9375 0.4375
vt 0.875 0.609375
vt 0.9375 0.609375
vt 0.9375 0.4375
vt 0.875 0.4375
vt 0.875 0.609375
vt 0.8125 0.609375
vt 0.8125 0.671875
vt 0.875 0.671875
vt 0.9375 0.671875
vt 0.875 0.671875
vt 0.875 0.609375
vt 0.9375 0.609375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 28/76/19 31/75/19 29/74/19 26/73/19
f 27/80/20 28/79/20 26/78/20 25/77/20
f 32/84/21 27/83/21 25/82/21 30/81/21
f 31/88/22 32/87/22 30/86/22 29/85/22
f 30/92/23 25/91/23 26/90/23 29/89/23
f 31/96/24 28/95/24 27/94/24 32/93/24
o Leg3A
v -0.0625 0.6875 -0.4375
v -0.0625 0.6875 -0.6875
v -0.0625 0 -0.4375
v -0.0625 0 -0.6875
v -0.3125 0.6875 -0.6875
v -0.3125 0.6875 -0.4375
v -0.3125 0 -0.6875
v -0.3125 0 -0.4375
vt 0.875 0.609375
vt 0.8125 0.609375
vt 0.8125 0.4375
vt 0.875 0.4375
vt 0.9375 0.609375
vt 0.875 0.609375
vt 0.875 0.4375
vt 0.9375 0.4375
vt 1 0.609375
vt 0.9375 0.609375
vt 0.9375 0.4375
vt 1 0.4375
vt 0.8125 0.609375
vt 0.75 0.609375
vt 0.75 0.4375
vt 0.8125 0.4375
vt 0.8125 0.609375
vt 0.875 0.609375
vt 0.875 0.671875
vt 0.8125 0.671875
vt 0.875 0.671875
vt 0.9375 0.671875
vt 0.9375 0.609375
vt 0.875 0.609375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 36/100/25 39/99/25 37/98/25 34/97/25
f 35/104/26 36/103/26 34/102/26 33/101/26
f 40/108/27 35/107/27 33/106/27 38/105/27
f 39/112/28 40/111/28 38/110/28 37/109/28
f 38/116/29 33/115/29 34/114/29 37/113/29
f 39/120/30 36/119/30 35/118/30 40/117/30
o Leg4A
v 0.3125 0.6875 -0.4375
v 0.3125 0.6875 -0.6875
v 0.3125 0 -0.4375
v 0.3125 0 -0.6875
v 0.0625 0.6875 -0.6875
v 0.0625 0.6875 -0.4375
v 0.0625 0 -0.6875
v 0.0625 0 -0.4375
vt 0.8125 0.609375
vt 0.875 0.609375
vt 0.875 0.4375
vt 0.8125 0.4375
vt 0.75 0.609375
vt 0.8125 0.609375
vt 0.8125 0.4375
vt 0.75 0.4375
vt 0.9375 0.609375
vt 1 0.609375
vt 1 0.4375
vt 0.9375 0.4375
vt 0.875 0.609375
vt 0.9375 0.609375
vt 0.9375 0.4375
vt 0.875 0.4375
vt 0.875 0.609375
vt 0.8125 0.609375
vt 0.8125 0.671875
vt 0.875 0.671875
vt 0.9375 0.671875
vt 0.875 0.671875
vt 0.875 0.609375
vt 0.9375 0.609375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 44/124/31 47/123/31 45/122/31 42/121/31
f 43/128/32 44/127/32 42/126/32 41/125/32
f 48/132/33 43/131/33 41/130/33 46/129/33
f 47/136/34 48/135/34 46/134/34 45/133/34
f 46/140/35 41/139/35 42/138/35 45/137/35
f 47/144/36 44/143/36 43/142/36 48/141/36
o Head
v 0.1875 2.051882938682637 -0.7896234122634724
v 0.1875 1.833132938682637 -1.1685095264191645
v 0.1875 1.78125 -0.6333734122634727
v 0.1875 1.5625 -1.0122595264191645
v -0.1875 1.833132938682637 -1.1685095264191645
v -0.1875 2.051882938682637 -0.7896234122634724
v -0.1875 1.5625 -1.0122595264191645
v -0.1875 1.78125 -0.6333734122634727
vt 0.109375 0.6875
vt 0.203125 0.6875
vt 0.203125 0.609375
vt 0.109375 0.609375
vt 0 0.6875
vt 0.109375 0.6875
vt 0.109375 0.609375
vt 0 0.609375
vt 0.3125 0.6875
vt 0.40625 0.6875
vt 0.40625 0.609375
vt 0.3125 0.609375
vt 0.203125 0.6875
vt 0.3125 0.6875
vt 0.3125 0.609375
vt 0.203125 0.609375
vt 0.203125 0.6875
vt 0.109375 0.6875
vt 0.109375 0.796875
vt 0.203125 0.796875
vt 0.296875 0.796875
vt 0.203125 0.796875
vt 0.203125 0.6875
vt 0.296875 0.6875
vn 0 -0.49999999999999994 -0.8660254037844387
vn 1 0 0
vn 0 0.49999999999999994 0.8660254037844387
vn -1 0 0
vn 0 0.8660254037844387 -0.49999999999999994
vn 0 -0.8660254037844387 0.49999999999999994
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 52/148/37 55/147/37 53/146/37 50/145/37
f 51/152/38 52/151/38 50/150/38 49/149/38
f 56/156/39 51/155/39 49/154/39 54/153/39
f 55/160/40 56/159/40 54/158/40 53/157/40
f 54/164/41 49/163/41 50/162/41 53/161/41
f 55/168/42 52/167/42 51/166/42 56/165/42
o UMouth
v 0.125 1.833132938682637 -1.1685095264191645
v 0.125 1.676882938682637 -1.4391424651018014
v 0.125 1.5625 -1.0122595264191645
v 0.125 1.40625 -1.2828924651018019
v -0.125 1.676882938682637 -1.4391424651018014
v -0.125 1.833132938682637 -1.1685095264191645
v -0.125 1.40625 -1.2828924651018019
v -0.125 1.5625 -1.0122595264191645
vt 0.078125 0.53125
vt 0.140625 0.53125
vt 0.140625 0.453125
vt 0.078125 0.453125
vt 0 0.53125
vt 0.078125 0.53125
vt 0.078125 0.453125
vt 0 0.453125
vt 0.21875 0.53125
vt 0.28125 0.53125
vt 0.28125 0.453125
vt 0.21875 0.453125
vt 0.140625 0.53125
vt 0.21875 0.53125
vt 0.21875 0.453125
vt 0.140625 0.453125
vt 0.140625 0.53125
vt 0.078125 0.53125
vt 0.078125 0.609375
vt 0.140625 0.609375
vt 0.203125 0.609375
vt 0.140625 0.609375
vt 0.140625 0.53125
vt 0.203125 0.53125
vn 0 -0.49999999999999994 -0.8660254037844387
vn 1 0 0
vn 0 0.49999999999999994 0.8660254037844387
vn -1 0 0
vn 0 0.8660254037844387 -0.49999999999999994
vn 0 -0.8660254037844387 0.49999999999999994
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 60/172/43 63/171/43 61/170/43 58/169/43
f 59/176/44 60/175/44 58/174/44 57/173/44
f 64/180/45 59/179/45 57/178/45 62/177/45
f 63/184/46 64/183/46 62/182/46 61/181/46
f 62/188/47 57/187/47 58/186/47 61/185/47
f 63/192/48 60/191/48 59/190/48 64/189/48
o Ear1
v -0.06465012057680164 2.1600077984998522 -0.8465349149312555
v -0.06192650361593727 2.1288767141844853 -0.9006615026677829
v -0.050497791707081574 1.9982459393130734 -0.7527849149312555
v -0.04777417474621726 1.9671148549977064 -0.8069115026677829
v -0.1864508408774055 2.117982246341028 -0.9006615026677829
v -0.1891744578382698 2.149113330656395 -0.8465349149312555
v -0.17229851200768548 1.9562203871542492 -0.8069115026677829
v -0.1750221289685498 1.987351471469616 -0.7527849149312555
vt 0.34375 0.734375
vt 0.3125 0.734375
vt 0.3125 0.6875
vt 0.34375 0.6875
vt 0.359375 0.734375
vt 0.34375 0.734375
vt 0.34375 0.6875
vt 0.359375 0.6875
vt 0.390625 0.734375
vt 0.359375 0.734375
vt 0.359375 0.6875
vt 0.390625 0.6875
vt 0.3125 0.734375
vt 0.296875 0.734375
vt 0.296875 0.6875
vt 0.3125 0.6875
vt 0.3125 0.734375
vt 0.34375 0.734375
vt 0.34375 0.75
vt 0.3125 0.75
vt 0.34375 0.75
vt 0.375 0.75
vt 0.375 0.734375
vt 0.34375 0.734375
vn 0.0435778713738291 -0.4980973490458729 -0.8660254037844388
vn 0.9961946980917455 0.0871557427476582 -1.3877787807814457e-17
vn -0.0435778713738291 0.4980973490458729 0.8660254037844388
vn -0.9961946980917455 -0.0871557427476582 1.3877787807814457e-17
vn -0.07547908730517335 0.862729915662821 -0.5
vn 0.07547908730517335 -0.862729915662821 0.5
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 68/196/49 71/195/49 69/194/49 66/193/49
f 67/200/50 68/199/50 66/198/50 65/197/50
f 72/204/51 67/203/51 65/202/51 70/201/51
f 71/208/52 72/207/52 70/206/52 69/205/52
f 70/212/53 65/211/53 66/210/53 69/209/53
f 71/216/54 68/215/54 67/214/54 72/213/54
o Ear2
v 0.1891744578382698 2.149113330656395 -0.8465349149312555
v 0.1864508408774055 2.117982246341028 -0.9006615026677829
v 0.17502212896854985 1.987351471469616 -0.7527849149312555
v 0.17229851200768542 1.9562203871542492 -0.8069115026677829
v 0.06192650361593732 2.1288767141844853 -0.9006615026677829
v 0.06465012057680164 2.1600077984998522 -0.8465349149312555
v 0.04777417474621726 1.9671148549977064 -0.8069115026677829
v 0.050497791707081574 1.9982459393130734 -0.7527849149312555
vt 0.3125 0.734375
vt 0.34375 0.734375
vt 0.34375 0.6875
vt 0.3125 0.6875
vt 0.296875 0.734375
vt 0.3125 0.734375
vt 0.3125 0.6875
vt 0.296875 0.6875
vt 0.359375 0.734375
vt 0.390625 0.734375
vt 0.390625 0.6875
vt 0.359375 0.6875
vt 0.34375 0.734375
vt 0.359375 0.734375
vt 0.359375 0.6875
vt 0.34375 0.6875
vt 0.34375 0.734375
vt 0.3125 0.734375
vt 0.3125 0.75
vt 0.34375 0.75
vt 0.375 0.75
vt 0.34375 0.75
vt 0.34375 0.734375
vt 0.375 0.734375
vn -0.0435778713738291 -0.4980973490458729 -0.8660254037844388
vn 0.9961946980917455 -0.0871557427476582 1.3877787807814457e-17
vn 0.0435778713738291 0.4980973490458729 0.8660254037844388
vn -0.9961946980917455 0.0871557427476582 -1.3877787807814457e-17
vn 0.07547908730517335 0.862729915662821 -0.5
vn -0.07547908730517335 -0.862729915662821 0.5
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 76/220/55 79/219/55 77/218/55 74/217/55
f 75/224/56 76/223/56 74/222/56 73/221/56
f 80/228/57 75/227/57 73/226/57 78/225/57
f 79/232/58 80/231/58 78/230/58 77/229/58
f 78/236/59 73/235/59 74/234/59 77/233/59
f 79/240/60 76/239/60 75/238/60 80/237/60
o MuleEarL
v -0.15935822581478865 2.3816773650616265 -0.9715349149312555
v -0.15127013065533484 2.351492182990093 -1.0256615026677829
v -0.061295283546407786 2.0157014821763357 -0.7527849149312555
v -0.05320718838695404 1.9855163001048024 -0.8069115026677829
v -0.27201085894146837 2.3191398023522782 -1.0256615026677829
v -0.28009895410092217 2.3493249844238115 -0.9715349149312555
v -0.1739479166730875 1.9531639194669874 -0.8069115026677829
v -0.1820360118325413 1.9833491015385207 -0.7527849149312555
vt 0.046875 0.796875
vt 0.015625 0.796875
vt 0.015625 0.6875
vt 0.046875 0.6875
vt 0.0625 0.796875
vt 0.046875 0.796875
vt 0.046875 0.6875
vt 0.0625 0.6875
vt 0.09375 0.796875
vt 0.0625 0.796875
vt 0.0625 0.6875
vt 0.09375 0.6875
vt 0.015625 0.796875
vt 0 0.796875
vt 0 0.6875
vt 0.015625 0.6875
vt 0.015625 0.796875
vt 0.046875 0.796875
vt 0.046875 0.8125
vt 0.015625 0.8125
vt 0.046875 0.8125
vt 0.078125 0.8125
vt 0.078125 0.796875
vt 0.046875 0.796875
vn 0.12940952255126037 -0.48296291314453416 -0.8660254037844387
vn 0.9659258262890683 0.25881904510252074 1.3877787807814457e-17
vn -0.12940952255126037 0.48296291314453416 0.8660254037844387
vn -0.9659258262890683 -0.25881904510252074 -1.3877787807814457e-17
vn -0.2241438680420134 0.8365163037378079 -0.5
vn 0.2241438680420134 -0.8365163037378079 0.5
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 84/244/61 87/243/61 85/242/61 82/241/61
f 83/248/62 84/247/62 82/246/62 81/245/62
f 88/252/63 83/251/63 81/250/63 86/249/63
f 87/256/64 88/255/64 86/254/64 85/253/64
f 86/260/65 81/259/65 82/258/65 85/257/65
f 87/264/66 84/263/66 83/262/66 88/261/66
o MuleEarR
v 0.2800989541009222 2.3493249844238115 -0.9715349149312555
v 0.27201085894146837 2.3191398023522782 -1.0256615026677829
v 0.18203601183254126 1.9833491015385207 -0.7527849149312555
v 0.1739479166730875 1.9531639194669874 -0.8069115026677829
v 0.1512701306553348 2.351492182990093 -1.0256615026677829
v 0.15935822581478865 2.3816773650616265 -0.9715349149312555
v 0.05320718838695404 1.9855163001048024 -0.8069115026677829
v 0.061295283546407786 2.0157014821763357 -0.7527849149312555
vt 0.015625 0.796875
vt 0.046875 0.796875
vt 0.046875 0.6875
vt 0.015625 0.6875
vt 0 0.796875
vt 0.015625 0.796875
vt 0.015625 0.6875
vt 0 0.6875
vt 0.0625 0.796875
vt 0.09375 0.796875
vt 0.09375 0.6875
vt 0.0625 0.6875
vt 0.046875 0.796875
vt 0.0625 0.796875
vt 0.0625 0.6875
vt 0.046875 0.6875
vt 0.046875 0.796875
vt 0.015625 0.796875
vt 0.015625 0.8125
vt 0.046875 0.8125
vt 0.078125 0.8125
vt 0.046875 0.8125
vt 0.046875 0.796875
vt 0.078125 0.796875
vn -0.12940952255126037 -0.48296291314453416 -0.8660254037844387
vn 0.9659258262890683 -0.25881904510252074 -1.3877787807814457e-17
vn 0.12940952255126037 0.48296291314453416 0.8660254037844387
vn -0.9659258262890683 0.25881904510252074 1.3877787807814457e-17
vn 0.2241438680420134 0.8365163037378079 -0.5
vn -0.2241438680420134 -0.8365163037378079 0.5
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 92/268/67 95/267/67 93/266/67 90/265/67
f 91/272/68 92/271/68 90/270/68 89/269/68
f 96/276/69 91/275/69 89/274/69 94/273/69
f 95/280/70 96/279/70 94/278/70 93/277/70
f 94/284/71 89/283/71 90/282/71 93/281/71
f 95/288/72 92/287/72 91/286/72 96/285/72
o Neck
v 0.125 1.7828924651018019 -0.6272436490538904
v 0.125 1.5641424651018019 -1.0061297632095823
v 0.125 1.1333734122634724 -0.2522436490538904
v 0.125 0.9146234122634727 -0.6311297632095825
v -0.125 1.5641424651018019 -1.0061297632095823
v -0.125 1.7828924651018019 -0.6272436490538904
v -0.125 0.9146234122634727 -0.6311297632095825
v -0.125 1.1333734122634724 -0.2522436490538904
vt 0.109375 0.34375
vt 0.171875 0.34375
vt 0.171875 0.15625
vt 0.109375 0.15625
vt 0 0.34375
vt 0.109375 0.34375
vt 0.109375 0.15625
vt 0 0.15625
vt 0.28125 0.34375
vt 0.34375 0.34375
vt 0.34375 0.15625
vt 0.28125 0.15625
vt 0.171875 0.34375
vt 0.28125 0.34375
vt 0.28125 0.15625
vt 0.171875 0.15625
vt 0.171875 0.34375
vt 0.109375 0.34375
vt 0.109375 0.453125
vt 0.171875 0.453125
vt 0.234375 0.453125
vt 0.171875 0.453125
vt 0.171875 0.34375
vt 0.234375 0.34375
vn 0 -0.49999999999999994 -0.8660254037844387
vn 1 0 0
vn 0 0.49999999999999994 0.8660254037844387
vn -1 0 0
vn 0 0.8660254037844387 -0.49999999999999994
vn 0 -0.8660254037844387 0.49999999999999994
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 100/292/73 103/291/73 101/290/73 98/289/73
f 99/296/74 100/295/74 98/294/74 97/293/74
f 104/300/75 99/299/75 97/298/75 102/297/75
f 103/304/76 104/303/76 102/302/76 101/301/76
f 102/308/77 97/307/77 98/306/77 101/305/77
f 103/312/78 100/311/78 99/310/78 104/309/78
o Mane
v 0.0625 2.1160254037844384 -0.6752404735808355
v 0.0625 2.0535254037844384 -0.7834936490538902
v 0.0625 1.25 -0.1752404735808355
v 0.0625 1.1875 -0.2834936490538904
v -0.0625 2.0535254037844384 -0.7834936490538902
v -0.0625 2.1160254037844384 -0.6752404735808355
v -0.0625 1.1875 -0.2834936490538904
v -0.0625 1.25 -0.1752404735808355
vt 0.90625 0.40625
vt 0.9375 0.40625
vt 0.9375 0.15625
vt 0.90625 0.15625
vt 0.875 0.40625
vt 0.90625 0.40625
vt 0.90625 0.15625
vt 0.875 0.15625
vt 0.96875 0.40625
vt 1 0.40625
vt 1 0.15625
vt 0.96875 0.15625
vt 0.9375 0.40625
vt 0.96875 0.40625
vt 0.96875 0.15625
vt 0.9375 0.15625
vt 0.9375 0.40625
vt 0.90625 0.40625
vt 0.90625 0.4375
vt 0.9375 0.4375
vt 0.96875 0.4375
vt 0.9375 0.4375
vt 0.9375 0.40625
vt 0.96875 0.40625
vn 0 -0.49999999999999994 -0.8660254037844387
vn 1 0 0
vn 0 0.49999999999999994 0.8660254037844387
vn -1 0 0
vn 0 0.8660254037844387 -0.49999999999999994
vn 0 -0.8660254037844387 0.49999999999999994
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 108/316/79 111/315/79 109/314/79 106/313/79
f 107/320/80 108/319/80 106/318/80 105/317/80
f 112/324/81 107/323/81 105/322/81 110/321/81
f 111/328/82 112/327/82 110/326/82 109/325/82
f 110/332/83 105/331/83 106/330/83 109/329/83
f 111/336/84 108/335/84 107/334/84 112/333/84
o Bag1
v 0.5 1.3125 0.125
v 0.3125000000000001 1.3125 0.12499999999999989
v 0.5 0.8125 0.125
v 0.3125000000000001 0.8125 0.12499999999999989
v 0.3125 1.3125 0.625
v 0.5 1.3125 0.625
v 0.3125 0.8125 0.625
v 0.5 0.8125 0.625
vt 0.453125 0.625
vt 0.578125 0.625
vt 0.578125 0.5
vt 0.453125 0.5
vt 0.40625 0.625
vt 0.453125 0.625
vt 0.453125 0.5
vt 0.40625 0.5
vt 0.625 0.625
vt 0.75 0.625
vt 0.75 0.5
vt 0.625 0.5
vt 0.578125 0.625
vt 0.625 0.625
vt 0.625 0.5
vt 0.578125 0.5
vt 0.578125 0.625
vt 0.453125 0.625
vt 0.453125 0.671875
vt 0.578125 0.671875
vt 0.703125 0.671875
vt 0.578125 0.671875
vt 0.578125 0.625
vt 0.703125 0.625
vn -1 0 -2.220446049250313e-16
vn 2.220446049250313e-16 0 -1
vn 1 0 2.220446049250313e-16
vn -2.220446049250313e-16 0 1
vn 0 1 0
vn 0 -1 0
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 116/340/85 119/339/85 117/338/85 114/337/85
f 115/344/86 116/343/86 114/342/86 113/341/86
f 120/348/87 115/347/87 113/346/87 118/345/87
f 119/352/88 120/351/88 118/350/88 117/349/88
f 118/356/89 113/355/89 114/354/89 117/353/89
f 119/360/90 116/359/90 115/358/90 120/357/90
o Bag2
v -0.5 1.3125 0.625
v -0.3125 1.3125 0.625
v -0.5 0.8125 0.625
v -0.3125 0.8125 0.625
v -0.3125000000000001 1.3125 0.12499999999999989
v -0.5000000000000001 1.3125 0.125
v -0.3125000000000001 0.8125 0.12499999999999989
v -0.5000000000000001 0.8125 0.125
vt 0.578125 0.625
vt 0.453125 0.625
vt 0.453125 0.5
vt 0.578125 0.5
vt 0.625 0.625
vt 0.578125 0.625
vt 0.578125 0.5
vt 0.625 0.5
vt 0.75 0.625
vt 0.625 0.625
vt 0.625 0.5
vt 0.75 0.5
vt 0.453125 0.625
vt 0.40625 0.625
vt 0.40625 0.5
vt 0.453125 0.5
vt 0.453125 0.625
vt 0.578125 0.625
vt 0.578125 0.671875
vt 0.453125 0.671875
vt 0.578125 0.671875
vt 0.703125 0.671875
vt 0.703125 0.625
vt 0.578125 0.625
vn 1 0 -2.220446049250313e-16
vn 2.220446049250313e-16 0 1
vn -1 0 2.220446049250313e-16
vn -2.220446049250313e-16 0 -1
vn 0 1 0
vn 0 -1 0
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 124/364/91 127/363/91 125/362/91 122/361/91
f 123/368/92 124/367/92 122/366/92 121/365/92
f 128/372/93 123/371/93 121/370/93 126/369/93
f 127/376/94 128/375/94 126/374/94 125/373/94
f 126/380/95 121/379/95 122/378/95 125/377/95
f 127/384/96 124/383/96 123/382/96 128/381/96
o Saddle
v 0.34375 1.34375 0.375
v 0.34375 1.34375 -0.25
v 0.34375 0.71875 0.375
v 0.34375 0.71875 -0.25
v -0.34375 1.34375 -0.25
v -0.34375 1.34375 0.375
v -0.34375 0.71875 -0.25
v -0.34375 0.71875 0.375
vt 0.546875 0.859375
vt 0.703125 0.859375
vt 0.703125 0.71875
vt 0.546875 0.71875
vt 0.40625 0.859375
vt 0.546875 0.859375
vt 0.546875 0.71875
vt 0.40625 0.71875
vt 0.84375 0.859375
vt 1 0.859375
vt 1 0.71875
vt 0.84375 0.71875
vt 0.703125 0.859375
vt 0.84375 0.859375
vt 0.84375 0.71875
vt 0.703125 0.71875
vt 0.703125 0.859375
vt 0.546875 0.859375
vt 0.546875 1
vt 0.703125 1
vt 0.859375 1
vt 0.703125 1
vt 0.703125 0.859375
vt 0.859375 0.859375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 132/388/97 135/387/97 133/386/97 130/385/97
f 131/392/98 132/391/98 130/390/98 129/389/98
f 136/396/99 131/395/99 129/394/99 134/393/99
f 135/400/100 136/399/100 134/398/100 133/397/100
f 134/404/101 129/403/101 130/402/101 133/401/101
f 135/408/102 132/407/102 131/406/102 136/405/102
o SaddleMouthL
v -0.125 1.6952722283113841 -1.1540063509461098
v -0.125 1.6327722283113841 -1.2622595264191645
v -0.125 1.587019052838329 -1.0915063509461098
v -0.125 1.524519052838329 -1.1997595264191645
v -0.1875 1.6327722283113841 -1.2622595264191645
v -0.1875 1.6952722283113841 -1.1540063509461098
v -0.1875 1.524519052838329 -1.1997595264191645
v -0.1875 1.587019052838329 -1.0915063509461098
vt 0.484375 0.890625
vt 0.5 0.890625
vt 0.5 0.859375
vt 0.484375 0.859375
vt 0.453125 0.890625
vt 0.484375 0.890625
vt 0.484375 0.859375
vt 0.453125 0.859375
vt 0.53125 0.890625
vt 0.546875 0.890625
vt 0.546875 0.859375
vt 0.53125 0.859375
vt 0.5 0.890625
vt 0.53125 0.890625
vt 0.53125 0.859375
vt 0.5 0.859375
vt 0.5 0.890625
vt 0.484375 0.890625
vt 0.484375 0.921875
vt 0.5 0.921875
vt 0.515625 0.921875
vt 0.5 0.921875
vt 0.5 0.890625
vt 0.515625 0.890625
vn 0 -0.49999999999999994 -0.8660254037844387
vn 1 0 0
vn 0 0.49999999999999994 0.8660254037844387
vn -1 0 0
vn 0 0.8660254037844387 -0.49999999999999994
vn 0 -0.8660254037844387 0.49999999999999994
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 140/412/103 143/411/103 141/410/103 138/409/103
f 139/416/104 140/415/104 138/414/104 137/413/104
f 144/420/105 139/419/105 137/418/105 142/417/105
f 143/424/106 144/423/106 142/422/106 141/421/106
f 142/428/107 137/427/107 138/426/107 141/425/107
f 143/432/108 140/431/108 139/430/108 144/429/108
o SaddleMouthR
v 0.1875 1.6952722283113841 -1.1540063509461098
v 0.1875 1.6327722283113841 -1.2622595264191645
v 0.1875 1.587019052838329 -1.0915063509461098
v 0.1875 1.524519052838329 -1.1997595264191645
v 0.125 1.6327722283113841 -1.2622595264191645
v 0.125 1.6952722283113841 -1.1540063509461098
v 0.125 1.524519052838329 -1.1997595264191645
v 0.125 1.587019052838329 -1.0915063509461098
vt 0.484375 0.890625
vt 0.5 0.890625
vt 0.5 0.859375
vt 0.484375 0.859375
vt 0.453125 0.890625
vt 0.484375 0.890625
vt 0.484375 0.859375
vt 0.453125 0.859375
vt 0.53125 0.890625
vt 0.546875 0.890625
vt 0.546875 0.859375
vt 0.53125 0.859375
vt 0.5 0.890625
vt 0.53125 0.890625
vt 0.53125 0.859375
vt 0.5 0.859375
vt 0.5 0.890625
vt 0.484375 0.890625
vt 0.484375 0.921875
vt 0.5 0.921875
vt 0.515625 0.921875
vt 0.5 0.921875
vt 0.5 0.890625
vt 0.515625 0.890625
vn 0 -0.49999999999999994 -0.8660254037844387
vn 1 0 0
vn 0 0.49999999999999994 0.8660254037844387
vn -1 0 0
vn 0 0.8660254037844387 -0.49999999999999994
vn 0 -0.8660254037844387 0.49999999999999994
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 148/436/109 151/435/109 149/434/109 146/433/109
f 147/440/110 148/439/110 146/438/110 145/437/110
f 152/444/111 147/443/111 145/442/111 150/441/111
f 151/448/112 152/447/112 150/446/112 149/445/112
f 150/452/113 145/451/113 146/450/113 149/449/113
f 151/456/114 148/455/114 147/454/114 152/453/114
o SaddleMouthLine
v -0.19374999999999998 1.6875 -0.21875
v -0.19374999999999998 1.6875 -1.21875
v -0.19374999999999998 1.5 -0.21875
v -0.19374999999999998 1.5 -1.21875
v -0.19374999999999998 1.6875 -1.21875
v -0.19374999999999998 1.6875 -0.21875
v -0.19374999999999998 1.5 -1.21875
v -0.19374999999999998 1.5 -0.21875
vt 0.75 0.71875
vt 0.75 0.71875
vt 0.75 0.671875
vt 0.75 0.671875
vt 0.5 0.71875
vt 0.75 0.71875
vt 0.75 0.671875
vt 0.5 0.671875
vt 1 0.71875
vt 1 0.71875
vt 1 0.671875
vt 1 0.671875
vt 0.75 0.71875
vt 1 0.71875
vt 1 0.671875
vt 0.75 0.671875
vt 0.75 0.71875
vt 0.75 0.71875
vt 0.75 0.96875
vt 0.75 0.96875
vt 0.75 0.96875
vt 0.75 0.96875
vt 0.75 0.71875
vt 0.75 0.71875
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 156/460/115 159/459/115 157/458/115 154/457/115
f 155/464/116 156/463/116 154/462/116 153/461/116
f 160/468/117 155/467/117 153/466/117 158/465/117
f 159/472/118 160/471/118 158/470/118 157/469/118
f 158/476/119 153/475/119 154/474/119 157/473/119
f 159/480/120 156/479/120 155/478/120 160/477/120
o SaddleMouthLineR
v 0.19374999999999998 1.6875 -0.21875
v 0.19374999999999998 1.6875 -1.21875
v 0.19374999999999998 1.5 -0.21875
v 0.19374999999999998 1.5 -1.21875
v 0.19374999999999998 1.6875 -1.21875
v 0.19374999999999998 1.6875 -0.21875
v 0.19374999999999998 1.5 -1.21875
v 0.19374999999999998 1.5 -0.21875
vt 0.75 0.71875
vt 0.75 0.71875
vt 0.75 0.671875
vt 0.75 0.671875
vt 0.5 0.71875
vt 0.75 0.71875
vt 0.75 0.671875
vt 0.5 0.671875
vt 1 0.71875
vt 1 0.71875
vt 1 0.671875
vt 1 0.671875
vt 0.75 0.71875
vt 1 0.71875
vt 1 0.671875
vt 0.75 0.671875
vt 0.75 0.71875
vt 0.75 0.71875
vt 0.75 0.96875
vt 0.75 0.96875
vt 0.75 0.96875
vt 0.75 0.96875
vt 0.75 0.71875
vt 0.75 0.71875
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 164/484/121 167/483/121 165/482/121 162/481/121
f 163/488/122 164/487/122 162/486/122 161/485/122
f 168/492/123 163/491/123 161/490/123 166/489/123
f 167/496/124 168/495/124 166/494/124 165/493/124
f 166/500/125 161/499/125 162/498/125 165/497/125
f 167/504/126 164/503/126 163/502/126 168/501/126
o HeadSaddle
v 0.140625 1.8561195507185708 -1.1566606162754502
v 0.140625 1.7779945507185708 -1.291977085616769
v 0.140625 1.5584233181676694 -0.9847856162754505
v 0.140625 1.4802983181676697 -1.120102085616769
v -0.140625 1.7779945507185708 -1.291977085616769
v -0.140625 1.8561195507185708 -1.1566606162754502
v -0.140625 1.4802983181676697 -1.120102085616769
v -0.140625 1.5584233181676694 -0.9847856162754505
vt 0.328125 0.96875
vt 0.390625 0.96875
vt 0.390625 0.890625
vt 0.328125 0.890625
vt 0.296875 0.96875
vt 0.328125 0.96875
vt 0.328125 0.890625
vt 0.296875 0.890625
vt 0.421875 0.96875
vt 0.484375 0.96875
vt 0.484375 0.890625
vt 0.421875 0.890625
vt 0.390625 0.96875
vt 0.421875 0.96875
vt 0.421875 0.890625
vt 0.390625 0.890625
vt 0.390625 0.96875
vt 0.328125 0.96875
vt 0.328125 1
vt 0.390625 1
vt 0.453125 1
vt 0.390625 1
vt 0.390625 0.96875
vt 0.453125 0.96875
vn 0 -0.49999999999999994 -0.8660254037844387
vn 1 0 0
vn 0 0.49999999999999994 0.8660254037844387
vn -1 0 0
vn 0 0.8660254037844387 -0.49999999999999994
vn 0 -0.8660254037844387 0.49999999999999994
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 172/508/127 175/507/127 173/506/127 170/505/127
f 171/512/128 172/511/128 170/510/128 169/509/128
f 176/516/129 171/515/129 169/514/129 174/513/129
f 175/520/130 176/519/130 174/518/130 173/517/130
f 174/524/131 169/523/131 170/522/131 173/521/131
f 175/528/132 172/527/132 171/526/132 176/525/132
o HeadSaddle
v 0.203125 2.074869550718571 -0.7777745021197584
v 0.203125 1.8404945507185708 -1.183723910143714
v 0.203125 1.7771733181676694 -0.6058995021197586
v 0.203125 1.5427983181676694 -1.0118489101437143
v -0.203125 1.8404945507185708 -1.183723910143714
v -0.203125 2.074869550718571 -0.7777745021197584
v -0.203125 1.5427983181676694 -1.0118489101437143
v -0.203125 1.7771733181676694 -0.6058995021197586
vt 0.109375 0.890625
vt 0.203125 0.890625
vt 0.203125 0.8125
vt 0.109375 0.8125
vt 0 0.890625
vt 0.109375 0.890625
vt 0.109375 0.8125
vt 0 0.8125
vt 0.3125 0.890625
vt 0.40625 0.890625
vt 0.40625 0.8125
vt 0.3125 0.8125
vt 0.203125 0.890625
vt 0.3125 0.890625
vt 0.3125 0.8125
vt 0.203125 0.8125
vt 0.203125 0.890625
vt 0.109375 0.890625
vt 0.109375 1
vt 0.203125 1
vt 0.296875 1
vt 0.203125 1
vt 0.203125 0.890625
vt 0.296875 0.890625
vn 0 -0.49999999999999994 -0.8660254037844387
vn 1 0 0
vn 0 0.49999999999999994 0.8660254037844387
vn -1 0 0
vn 0 0.8660254037844387 -0.49999999999999994
vn 0 -0.8660254037844387 0.49999999999999994
usemtl m_1e01f934-e3cf-9457-589b-e2903555a409
f 180/532/133 183/531/133 181/530/133 178/529/133
f 179/536/134 180/535/134 178/534/134 177/533/134
f 184/540/135 179/539/135 177/538/135 182/537/135
f 183/544/136 184/543/136 182/542/136 181/541/136
f 182/548/137 177/547/137 178/546/137 181/545/137
f 183/552/138 180/551/138 179/550/138 184/549/138