# Made in Blockbench 4.9.4
mtllib materials.mtl

o bottom
v 0.625 0.12500000000000022 -0.5000000000000002
v 0.625 2.220446049250313e-16 -0.5000000000000002
v 0.625 0.125 0.4999999999999998
v 0.625 0 0.4999999999999998
v -0.625 2.220446049250313e-16 -0.5000000000000002
v -0.625 0.12500000000000022 -0.5000000000000002
v -0.625 0 0.4999999999999998
v -0.625 0.125 0.4999999999999998
vt 0.34375 0.625
vt 0.03125 0.625
vt 0.03125 0.125
vt 0.34375 0.125
vt 0.375 0.625
vt 0.34375 0.625
vt 0.34375 0.125
vt 0.375 0.125
vt 0.6875 0.625
vt 0.375 0.625
vt 0.375 0.125
vt 0.6875 0.125
vt 0.03125 0.625
vt 0 0.625
vt 0 0.125
vt 0.03125 0.125
vt 0.03125 0.625
vt 0.34375 0.625
vt 0.34375 0.6875
vt 0.03125 0.6875
vt 0.34375 0.6875
vt 0.65625 0.6875
vt 0.65625 0.625
vt 0.34375 0.625
vn 0 -1 -2.220446049250313e-16
vn 1 0 0
vn 0 1 2.220446049250313e-16
vn -1 0 0
vn 0 2.220446049250313e-16 -1
vn 0 -2.220446049250313e-16 1
usemtl m_51fd1571-03b7-0fcd-9442-72fc6c7ce0a3
f 4/4/1 7/3/1 5/2/1 2/1/1
f 3/8/2 4/7/2 2/6/2 1/5/2
f 8/12/3 3/11/3 1/10/3 6/9/3
f 7/16/4 8/15/4 6/14/4 5/13/4
f 6/20/5 1/19/5 2/18/5 5/17/5
f 7/24/6 4/23/6 3/22/6 8/21/6
o front
v 0.625 0.625 -0.5
v 0.5 0.625 -0.5
v 0.625 0.125 -0.5
v 0.5 0.125 -0.5
v 0.4999999999999999 0.625 0.5
v 0.625 0.625 0.5
v 0.4999999999999999 0.125 0.5
v 0.625 0.125 0.5
vt 0.28125 0.9375
vt 0.03125 0.9375
vt 0.03125 0.6875
vt 0.28125 0.6875
vt 0.3125 0.9375
vt 0.28125 0.9375
vt 0.28125 0.6875
vt 0.3125 0.6875
vt 0.5625 0.9375
vt 0.3125 0.9375
vt 0.3125 0.6875
vt 0.5625 0.6875
vt 0.03125 0.9375
vt 0 0.9375
vt 0 0.6875
vt 0.03125 0.6875
vt 0.03125 0.9375
vt 0.28125 0.9375
vt 0.28125 1
vt 0.03125 1
vt 0.28125 1
vt 0.53125 1
vt 0.53125 0.9375
vt 0.28125 0.9375
vn -1 0 -2.220446049250313e-16
vn 2.220446049250313e-16 0 -1
vn 1 0 2.220446049250313e-16
vn -2.220446049250313e-16 0 1
vn 0 1 0
vn 0 -1 0
usemtl m_51fd1571-03b7-0fcd-9442-72fc6c7ce0a3
f 12/28/7 15/27/7 13/26/7 10/25/7
f 11/32/8 12/31/8 10/30/8 9/29/8
f 16/36/9 11/35/9 9/34/9 14/33/9
f 15/40/10 16/39/10 14/38/10 13/37/10
f 14/44/11 9/43/11 10/42/11 13/41/11
f 15/48/12 12/47/12 11/46/12 16/45/12
o back
v -0.6249999999999999 0.625 0.5
v -0.4999999999999999 0.625 0.5
v -0.6249999999999999 0.125 0.5
v -0.4999999999999999 0.125 0.5
v -0.5000000000000001 0.625 -0.5
v -0.6250000000000001 0.625 -0.5
v -0.5000000000000001 0.125 -0.5
v -0.6250000000000001 0.125 -0.5
vt 0.28125 0.9375
vt 0.03125 0.9375
vt 0.03125 0.6875
vt 0.28125 0.6875
vt 0.3125 0.9375
vt 0.28125 0.9375
vt 0.28125 0.6875
vt 0.3125 0.6875
vt 0.5625 0.9375
vt 0.3125 0.9375
vt 0.3125 0.6875
vt 0.5625 0.6875
vt 0.03125 0.9375
vt 0 0.9375
vt 0 0.6875
vt 0.03125 0.6875
vt 0.03125 0.9375
vt 0.28125 0.9375
vt 0.28125 1
vt 0.03125 1
vt 0.28125 1
vt 0.53125 1
vt 0.53125 0.9375
vt 0.28125 0.9375
vn 1 0 -2.220446049250313e-16
vn 2.220446049250313e-16 0 1
vn -1 0 2.220446049250313e-16
vn -2.220446049250313e-16 0 -1
vn 0 1 0
vn 0 -1 0
usemtl m_51fd1571-03b7-0fcd-9442-72fc6c7ce0a3
f 20/52/13 23/51/13 21/50/13 18/49/13
f 19/56/14 20/55/14 18/54/14 17/53/14
f 24/60/15 19/59/15 17/58/15 22/57/15
f 23/64/16 24/63/16 22/62/16 21/61/16
f 22/68/17 17/67/17 18/66/17 21/65/17
f 23/72/18 20/71/18 19/70/18 24/69/18
o right
v -0.49999999999999994 0.625 -0.5
v -0.5000000000000001 0.625 -0.37500000000000006
v -0.49999999999999994 0.125 -0.5
v -0.5000000000000001 0.125 -0.37500000000000006
v 0.5 0.625 -0.3749999999999999
v 0.5 0.625 -0.49999999999999994
v 0.5 0.125 -0.3749999999999999
v 0.5 0.125 -0.49999999999999994
vt 0.28125 0.9375
vt 0.03125 0.9375
vt 0.03125 0.6875
vt 0.28125 0.6875
vt 0.3125 0.9375
vt 0.28125 0.9375
vt 0.28125 0.6875
vt 0.3125 0.6875
vt 0.5625 0.9375
vt 0.3125 0.9375
vt 0.3125 0.6875
vt 0.5625 0.6875
vt 0.03125 0.9375
vt 0 0.9375
vt 0 0.6875
vt 0.03125 0.6875
vt 0.03125 0.9375
vt 0.28125 0.9375
vt 0.28125 1
vt 0.03125 1
vt 0.28125 1
vt 0.53125 1
vt 0.53125 0.9375
vt 0.28125 0.9375
vn -1.2246467991473532e-16 0 1
vn -1 0 -1.2246467991473532e-16
vn 1.2246467991473532e-16 0 -1
vn 1 0 1.2246467991473532e-16
vn 0 1 0
vn 0 -1 0
usemtl m_51fd1571-03b7-0fcd-9442-72fc6c7ce0a3
f 28/76/19 31/75/19 29/74/19 26/73/19
f 27/80/20 28/79/20 26/78/20 25/77/20
f 32/84/21 27/83/21 25/82/21 30/81/21
f 31/88/22 32/87/22 30/86/22 29/85/22
f 30/92/23 25/91/23 26/90/23 29/89/23
f 31/96/24 28/95/24 27/94/24 32/93/24
o left
v 0.5 0.625 0.5
v 0.5 0.625 0.375
v 0.5 0.125 0.5
v 0.5 0.125 0.375
v -0.5 0.625 0.375
v -0.5 0.625 0.5
v -0.5 0.125 0.375
v -0.5 0.125 0.5
vt 0.28125 0.9375
vt 0.03125 0.9375
vt 0.03125 0.6875
vt 0.28125 0.6875
vt 0.3125 0.9375
vt 0.28125 0.9375
vt 0.28125 0.6875
vt 0.3125 0.6875
vt 0.5625 0.9375
vt 0.3125 0.9375
vt 0.3125 0.6875
vt 0.5625 0.6875
vt 0.03125 0.9375
vt 0 0.9375
vt 0 0.6875
vt 0.03125 0.6875
vt 0.03125 0.9375
vt 0.28125 0.9375
vt 0.28125 1
vt 0.03125 1
vt 0.28125 1
vt 0.53125 1
vt 0.53125 0.9375
vt 0.28125 0.9375
vn 0 0 -1
vn 1 0 0
vn 0 0 1
vn -1 0 0
vn 0 1 0
vn 0 -1 0
usemtl m_51fd1571-03b7-0fcd-9442-72fc6c7ce0a3
f 36/100/25 39/99/25 37/98/25 34/97/25
f 35/104/26 36/103/26 34/102/26 33/101/26
f 40/108/27 35/107/27 33/106/27 38/105/27
f 39/112/28 40/111/28 38/110/28 37/109/28
f 38/116/29 33/115/29 34/114/29 37/113/29
f 39/120/30 36/119/30 35/118/30 40/117/30